import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate'

import actions from './actions.js';
import getters from './getters.js';
import app from './modules/app.js'
import temp from './modules/temp.js'
import mutations from './mutations.js';
import state from './state.js';
Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {app, temp},
  plugins: [persistedState({
    key: 'bpm-localstorage',
    storege: window.localStorage,
    paths: ['app', 'temp']
  })],
  strict: process.env.NODE_ENV !== 'production'
});
