export class SystemController {
  static login (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/login/doLogin', 'post', params, axiosOption, httpOption);
  }

  static logout (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/login/doLogout', 'post', params, axiosOption, httpOption);
  }

  static wxLogin (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/login/WxLogin', 'post', params, axiosOption, httpOption)
  }

  static changePassword (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/login/changePassword', 'post', params, axiosOption, httpOption);
  }

  static getLoginInfo (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/login/getLoginInfo', 'get', params, axiosOption, httpOption);
  }

  static getDictList (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysDict/list', 'post', params, axiosOption, httpOption);
  }

  static getRoleList (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysRole/list', 'post', params, axiosOption, httpOption);
  }

  static getRole (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysRole/view', 'get', params, axiosOption, httpOption);
  }

  static deleteRole (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysRole/delete', 'post', params, axiosOption, httpOption);
  }

  static addRole (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysRole/add', 'post', params, axiosOption, httpOption);
  }

  static updateRole (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysRole/update', 'post', params, axiosOption, httpOption);
  }

  static getUserList (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysUser/list', 'post', params, axiosOption, httpOption);
  }

  static getUser (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysUser/view', 'get', params, axiosOption, httpOption);
  }

  static resetUserPassword (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysUser/resetPassword', 'post', params, axiosOption, httpOption);
  }

  static deleteUser (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysUser/delete', 'post', params, axiosOption, httpOption);
  }

  static addUser (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysUser/add', 'post', params, axiosOption, httpOption);
  }

  static updateUser (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysUser/update', 'post', params, axiosOption, httpOption);
  }

  static addDepartment (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysDept/add', 'post', params, axiosOption, httpOption);
  }

  static deleteDepartment (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysDept/delete', 'post', params, axiosOption, httpOption);
  }

  static updateDepartment (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysDept/update', 'post', params, axiosOption, httpOption);
  }

  static getDepartmentList (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysDept/list', 'post', params, axiosOption, httpOption);
  }

  // 菜单接口
  static getMenuPermList (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysMenu/list', 'post', params, axiosOption, httpOption);
  }

  static addMenu (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysMenu/add', 'post', params, axiosOption, httpOption);
  }

  static updateMenu (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysMenu/update', 'post', params, axiosOption, httpOption);
  }

  static deleteMenu (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysMenu/delete', 'post', params, axiosOption, httpOption);
  }

  static viewMenu (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysMenu/view', 'get', params, axiosOption, httpOption);
  }
  /**
   * @param params    {roleId, searchString}
   */
  static listRoleUser (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysRole/listUserRole', 'post', params, axiosOption, httpOption);
  }

  static listNotInUserRole (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysRole/listNotInUserRole', 'post', params, axiosOption, httpOption);
  }

  /**
   * @param params    {roleId, userIdListString}
   */
  static addRoleUser (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysRole/addUserRole', 'post', params, axiosOption, httpOption);
  }

  /**
   * @param params    {roleId, userId}
   */
  static deleteRoleUser (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysRole/deleteUserRole', 'post', params, axiosOption, httpOption);
  }

  /**
   * @param params {}
   */
  static queryRoleByPermCode (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysRole/listAllRolesByPermCode', 'post', params, axiosOption, httpOption);
  }
  // 操作日志
  static listSysOperationLog (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/sysOperationLog/list', 'post', params, axiosOption, httpOption);
  }
  // 在线用户
  static listSysLoginUser (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/loginUser/list', 'post', params, axiosOption, httpOption);
  }

  static deleteSysLoginUser (sender, params, axiosOption, httpOption) {
    return sender.doUrl('admin/upms/loginUser/delete', 'post', params, axiosOption, httpOption);
  }

  static getCurrentFormJson (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/nuxtapi/system/getCurrentFormJson', 'get', params, axiosOption, httpOption);
  }

  static getHistoryByFormId (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/nuxtapi/system/getHistoryByFormId', 'get', params, axiosOption, httpOption);
  }

  static getHistoryById (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/nuxtapi/system/getHistoryById', 'get', params, axiosOption, httpOption);
  }

  static feedback (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/nuxtapi/system/feedback', 'post', params, axiosOption, httpOption);
  }
}
