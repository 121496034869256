<template>
  <div @click="selectProcess">
    <div class="node-wrap" :class="{gateway: nodeConfig.type === FlowNodeType.CONDITIONAL_BRANCH, current: unfinishedTaskSet.indexOf(nodeConfig?.element?.id) > -1, success: finishedTaskSet.indexOf(nodeConfig?.element?.id) > -1 && unfinishedTaskSet.indexOf(nodeConfig?.element?.id) === -1, start: nodeConfig.type === FlowNodeType.ORIGINATOR}"
         v-if="[FlowNodeType.CONDITIONAL_BRANCH, FlowNodeType.PARALLEL_BRANCH].indexOf(nodeConfig.type) === -1">
      <div class="node-wrap-box" :class="(nodeConfig.type === FlowNodeType.ORIGINATOR ? 'start-node ' : '') + (isActiveId === nodeConfig.element.id ? 'active ' : '') + (nodeConfig.nodeUserList.length === 0 && !readonly ? 'error' : '')">
        <div @click.stop="setPerson()">
          <div class="title" :key="nodeConfig.type" :style="`background: rgb(${bgColor});`">
            <span v-if="nodeConfig.type === FlowNodeType.ORIGINATOR">{{ nodeConfig.nodeName }}</span>
            <template v-else>
              <img class="iconfont" src="@/assets/img/sp1.png" alt="">
              <span class="editable-title">{{ nodeConfig.nodeName }}</span>
              <i v-if="!readonly" class="anticon anticon-close close el-icon-close" @click.stop="delNode"></i>
            </template>
          </div>
          <div class="content">
            <div class="text">
              <span class="placeholder" v-if="!showText">设置{{ defaultText }}</span>
              <span v-else>{{ showText }}</span>
            </div>
            <!-- <i class="anticon anticon-right arrow el-icon-arrow-right"></i> -->
          </div>
          <div class="error_tip" v-if="isTried && nodeConfig.error">
            <i class="anticon anticon-exclamation-circle"></i>
          </div>
        </div>
      </div>
      <addNode :class="{active: finishedTaskSet.indexOf(nodeConfig?.element?.id) > -1}" :childNodeP.sync="nodeConfig.childNode" :key="'a' + nodeConfig.type + nodeConfig?.element?.id" :isGateway="[FlowNodeType.CONDITIONAL_BRANCH, FlowNodeType.PARALLEL_BRANCH].indexOf(nodeConfig.type) > -1" :nodeConfig="nodeConfig"></addNode>
    </div>
    <div class="branch-wrap" v-if="[FlowNodeType.CONDITIONAL_BRANCH, FlowNodeType.PARALLEL_BRANCH].indexOf(nodeConfig.type) > -1">
      <div class="branch-box-wrap">
        <div class="branch-box">
          <button class="add-branch" @click.stop="addTerm" ref="addBranch" :class="{readonly: readonly}">
            {{ nodeConfig.type === FlowNodeType.CONDITIONAL_BRANCH ? '添加条件' : '添加分支' }}
          </button>
          <div class="col-box" ref="colBox" v-for="(item, index) in nodeConfig.conditionNodes" :key="nodeConfig?.element?.id + item?.element?.id + index" :class="{active: finishedSequenceFlowSet.indexOf(item?.element?.id) > -1, done: isDone(item)}">
            <div class="top-line" :key="'top-line' + nodeConfig?.element?.id + item?.element?.id + index" :style="'width:' + widths[index] + 'px'" :class="getLineConditionNodesClass(index)"></div>
            <div class="end-line" v-if="isDone(item)" :key="'end-line' + nodeConfig?.element?.id + item?.element?.id + index" :style="'width:' + widths[index] + 'px'"
                 :class="getLineConditionNodesClass(index)"></div>
            <div class="condition-node">
              <div class="condition-node-box">
                <div @click.stop="setPerson(item.priorityLevel)" class="auto-judge" :class="{success: finishedSequenceFlowSet.indexOf(item?.element?.id) > -1, active: isActiveId === item.element.id}">
                  <div class="title-wrapper">
                    <span class="editable-title" v-if="nodeConfig.type === FlowNodeType.CONDITIONAL_BRANCH">{{ item.nodeName || '满足条件' }}</span>
                    <span class="editable-title" v-if="nodeConfig.type === FlowNodeType.PARALLEL_BRANCH">{{ item.nodeName || '并行分支' }}</span>
                    <div>
                      <span class="priority-title">{{ nodeConfig.type === FlowNodeType.CONDITIONAL_BRANCH ? '优先级' : '分支' }}{{ index + 1 }}</span>
                      <i v-if="!readonly" class="anticon anticon-close close el-icon-close" @click.stop="delTerm(index)"></i>
                    </div>
                  </div>
                  <div class="content">
                    {{ conditionStr(nodeConfig, index) }}
                  </div>
                  <div class="error_tip" v-if="isTried && item.error">
                    <i class="anticon anticon-exclamation-circle"></i>
                  </div>
                </div>
                <addNode :key="'a' + item.type + item?.element?.id" :childNodeP.sync="item.childNode" :priorityLevel="item.priorityLevel" :nodeConfig="nodeConfig"></addNode>
              </div>
            </div>
            <nodeWrap :key="'c' + item?.element?.id  + (item?.childNode?.id || '')" v-if="item.childNode" :nodeConfig.sync="item.childNode" :readonly="readonly" :parent="item" @elementClick="elementClick"></nodeWrap>
            <template v-if="!index">
              <div class="top-left-cover-line"></div>
              <div class="bottom-left-cover-line"></div>
            </template>
            <template v-if="index === nodeConfig.conditionNodes.length - 1">
              <div class="top-right-cover-line"></div>
              <div class="bottom-right-cover-line"></div>
            </template>
          </div>
        </div>
        <addNode :class="{active: finishedTaskSet.indexOf(nodeConfig?.childNode?.element?.id) > -1}" :childNodeP.sync="nodeConfig.childNode" :key="'a' + nodeConfig.type + nodeConfig?.element?.id" :isEnd="true" :nodeConfig="nodeConfig"></addNode>
      </div>
    </div>
    <nodeWrap v-if="nodeConfig.childNode" :key="'b' + nodeConfig?.element?.id + (nodeConfig?.childNode?.id || '')" :nodeConfig.sync="nodeConfig.childNode" @elementClick="elementClick" :readonly="readonly" :parent="nodeConfig"></nodeWrap>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import addNode from './addNode'

export default {
  props: {
    nodeConfig: {
      type: Object
    },
    parent: {
      type: Object
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: { addNode },
  provide () {
    return {
      nodeConfig: this.nodeConfig,
      readonly: this.readonly,
      parent: this.parent
    }
  },
  inject: ['bpmnModeler', 'groupMap', 'deptPostMap', 'postMap', 'roleMap', 'finishedTaskSet', 'finishedSequenceFlowSet', 'unfinishedTaskSet'],
  data () {
    return {
      placeholderList: ['发起人', '审核人', '抄送人'],
      isInputList: [],
      isInput: false,
      isTried: false,
      showText: '',
      endId: '',
      widths: [],
      isActiveId: ''
    }
  },
  watch: {
    'nodeConfig': {
      immediate: true,
      deep: true,
      handler () {
        if (this.nodeConfig.nodeUserList) {
          this.showText = this.setApproverStr(this.nodeConfig)
        }
      }
    },
    'nodeConfig.conditionNodes': {
      deep: true,
      handler () {
        this.$nextTick(this.getConditionNodesLineWidth)
      }
    }
  },
  mounted () {
    this.endId = this.bpmnModeler.get('elementRegistry').find(el => el.type === 'bpmn:EndEvent').id
    // 监听选择事件，修改当前激活的元素以及表单
    this.bpmnModeler.on('selection.changed', ({ newSelection }) => {
      this.isActiveId = newSelection[0]?.id
    })
    this.bpmnModeler.on('element.changed', ({ element }) => {
      if (this.nodeConfig.type === this.FlowNodeType.CONDITIONAL_BRANCH) {
        this.nodeConfig.conditionNodes.forEach(row => {
          if (element.id === row?.element?.id) {
            let extensionElements = row.element.businessObject.get('extensionElements') || this.bpmnModeler.get('moddle').create('bpmn:ExtensionElements', { values: [] })
            let conditionExpression = row.element.businessObject.get('conditionExpression') || this.bpmnModeler.get('moddle').create('bpmn:FormalExpression');
            let customCondition = (extensionElements.values || []).filter(ex => ex.$type === 'flowable:CustomCondition')[0]
            if (row.element.source && row.element.source.businessObject.default?.id === row.element.id) {
              row.conditionStr = '默认流转路径'
            } else if (!row.element.businessObject.conditionExpression) {
              row.conditionStr = '普通流转路径'
            } else if (customCondition && customCondition.type === 'operation') {
              row.conditionStr = '内置按钮'
            } else {
              row.conditionStr = '条件流转路径'
            }
            row.extensionElements = extensionElements
            row.conditionExpression = conditionExpression
            row.nodeName = element.businessObject.name || ''
          }
        })
        if (element.id === this.nodeConfig?.element?.id) {
          this.nodeConfig.nodeName = element.businessObject.name || '网关'
        }
      } else if (this.nodeConfig.type === this.FlowNodeType.PARALLEL_BRANCH) {
        this.nodeConfig.conditionNodes.forEach(row => {
          if (element.id === row?.element?.id) {
            row.nodeName = element.businessObject.name || ''
          }
        })
      } else {
        if (element.id === this.nodeConfig?.element?.id) {
          let name = ''
          if (this.nodeConfig.type === this.FlowNodeType.ORIGINATOR) {
            name = '发起人'
          } else if (this.nodeConfig.type === this.FlowNodeType.APPROVED_BY) {
            name = '审批人'
          }
          this.nodeConfig.nodeName = element.businessObject.name || name
          this.nodeConfig.nodeUserList = this.getNodeUserList(element)
          this.showText = this.setApproverStr(this.nodeConfig)
        }
      }
    })
    if (this.nodeConfig.type === this.FlowNodeType.APPROVED_BY) {
      this.nodeConfig.error = !this.setApproverStr(this.nodeConfig)
    } else if (this.nodeConfig.type === this.FlowNodeType.CONDITIONAL_BRANCH) {
      this.resetConditionNodesErr()
    }
    this.getConditionNodesLineWidth()
  },
  computed: {
    defaultText () {
      return this.placeholderList[this.nodeConfig.type]
    },
    bgColor () {
      return ['144, 145, 150', '98, 168, 252', '255, 164, 85'][this.nodeConfig.type]
    },
    ...mapGetters(['getUserShowNameData'])
  },
  methods: {
    // clickEvent (index) {
    //   if (index || index === 0) {
    //     this.$set(this.isInputList, index, true)
    //   } else {
    //     this.isInput = true
    //   }
    // },
    // 删除任务节点
    delNode () {
      this.bpmnModeler.get('modeling').removeElements([this.nodeConfig.element])
      this.$emit('update:nodeConfig', this.nodeConfig.childNode)
    },
    // 添加分支
    addTerm () {
      if (this.readonly) return
      let len = this.nodeConfig.conditionNodes.length
      let obj = {
        nodeName: '',
        type: this.FlowNodeType.CONNECTING_LINE,
        priorityLevel: len,
        extensionElements: undefined,
        conditionExpression: undefined,
        conditionStr: this.nodeConfig.type === this.FlowNodeType.CONDITIONAL_BRANCH ? '普通流转路径' : '并行任务（同时进行）',
        nodeUserList: [],
        element: this.bpmnModeler.get('modeling').connect(this.nodeConfig.element, this.nodeConfig.endElement),
        childNode: undefined
      }
      this.nodeConfig.conditionNodes.push(obj)
      this.updateConnectData(obj)
      this.resetConditionNodesErr()
      this.$emit('update:nodeConfig', this.nodeConfig)
    },
    // 删除分支
    delTerm (index) {
      // 需要删除节点数组组
      const delNodes = []
      // 如果只有两个分支删除一个则需要删除分支节点
      if (this.nodeConfig.conditionNodes.length === 2) {
        delNodes.push(this.nodeConfig.element)
        this.nodeConfig.endElement && delNodes.push(this.nodeConfig.endElement)
      } else {
        delNodes.push(this.nodeConfig.conditionNodes[index]?.element)
      }
      // 获取当前分支条件线下的所有节点
      this.findNextChild(this.nodeConfig.conditionNodes[index], delNodes)
      // 删除当前条件节点和支条件节点下的所有节点
      this.bpmnModeler.get('modeling').removeElements(delNodes)
      this.nodeConfig.conditionNodes.splice(index, 1)
      this.nodeConfig.conditionNodes.length > 1 && this.nodeConfig.conditionNodes.forEach((item, index) => {
        item.priorityLevel = index
        this.updateConnectData(item)
      })
      this.resetConditionNodesErr()
      this.$emit('update:nodeConfig', this.nodeConfig)
      if (this.nodeConfig.conditionNodes.length === 1) {
        if (this.nodeConfig.childNode) {
          if (this.nodeConfig.conditionNodes[0].childNode) {
            this.reData(this.nodeConfig.conditionNodes[0].childNode, this.nodeConfig.childNode)
          } else {
            this.nodeConfig.conditionNodes[0].childNode = this.nodeConfig.childNode
          }
        }
        this.$emit('update:nodeConfig', this.nodeConfig.conditionNodes[0].childNode || this.nodeConfig.childNode)
      }
    },
    reData (data, addData) {
      !data.childNode ? data.childNode = addData : this.reData(data.childNode, addData)
    },
    setPerson (priorityLevel) {
      const {
        nodeConfig: {
          type,
          element,
          conditionNodes
        }
      } = this
      if ((type === this.FlowNodeType.CONDITIONAL_BRANCH || type === this.FlowNodeType.PARALLEL_BRANCH) && priorityLevel !== undefined) {
        this.bpmnModeler.get('selection').select(conditionNodes[priorityLevel].element)
        this.elementClick(conditionNodes[priorityLevel].element)
      } else {
        this.bpmnModeler.get('selection').select(element)
        this.elementClick(element)
      }
    },
    elementClick (element) {
      this.$emit('elementClick', element)
    },
    selectProcess () {
      const process = this.bpmnModeler.get('elementRegistry').find(el => el.type === 'bpmn:Process')
      this.bpmnModeler.get('selection').select(process)
    },
    resetConditionNodesErr () {
      for (let i = 0; i < this.nodeConfig.conditionNodes.length; i++) {
        this.nodeConfig.conditionNodes[i].error = !this.conditionStr(this.nodeConfig, i) && i !== this.nodeConfig.conditionNodes.length - 1
      }
    },
    setApproverStr (nodeConfig) {
      switch (nodeConfig.groupType) {
        case 'ASSIGNEE':
        case 'USERS':
          return nodeConfig.nodeUserList.map((item) => this.getUserShowNameData[item.id] || item.name).toString()
        case 'DEPT':
          return nodeConfig.nodeUserList.map((item) => this.groupMap?.get(item.id)?.name || item.name).toString()
        case 'ROLE':
          return nodeConfig.nodeUserList.map((item) => this.roleMap?.get(item.id)?.name || item.name).toString()
        case 'POST':
          return nodeConfig.nodeUserList.map((item) => {
            if (item.type === 'allDeptPost') {
              return '全部' + ' / ' + (this.postMap?.get(item.id)?.name || item.name)
            } else if (item.type === 'selfDeptPost') {
              return '本部门' + ' / ' + (this.postMap?.get(item.id)?.name || item.name)
            } else if (item.type === 'siblingDeptPost') {
              return '同级部门' + ' / ' + (this.postMap?.get(item.id)?.name || item.name)
            } else if (item.type === 'upDeptPost') {
              return '上级部门' + ' / ' + (this.postMap?.get(item.id)?.name || item.name)
            } else if (item.type === 'deptPost') {
              return this.deptPostMap?.get(item.id)?.deptName + ' / ' + (this.deptPostMap?.get(item.id)?.postShowName || item.name)
            }
          }).toString()
        case 'DEPT_POST_LEADER':
          return '流程发起人部门领导'
        case 'UP_DEPT_POST_LEADER':
          return '流程发起人上级部门领导'
        case 'CUR_UP_DEPT_POST_LEADER':
          return '直属领导'
        case 'CUR_DEPT_LEADER':
          return '部门领导'
        case 'START_USER_FIRST_LEVEL_DEPT_LEADER_NAME':
          return '一级领导'
        case 'START_USER_SECOND_LEVEL_DEPT_LEADER_NAME':
          return '二级领导'
        case 'START_USER_THIRD_LEVEL_DEPT_LEADER_NAME':
          return '三级领导'
        case 'START_USER_FOUR_LEVEL_DEPT_LEADER_NAME':
          return '四级领导'
        default:
          console.log('nerver')
      }
    },
    conditionStr (nodeConfig, index) {
      return nodeConfig.conditionNodes[index].conditionStr
    },
    getNodeUserList (element) {
      let formKey = {}
      try {
        formKey = JSON.parse(element?.businessObject?.formKey)
        this.nodeConfig.groupType = formKey.groupType
      } catch (error) {
      }
      switch (formKey.groupType) {
        case 'ASSIGNEE':
          return (element?.businessObject?.assignee?.split(',') || []).map(row => ({ id: row, name: row }))
        case 'USERS':
          return (element?.businessObject?.candidateUsers?.split(',') || []).map(row => ({ id: row, name: row }))
        case 'DEPT':
          return (element?.businessObject?.candidateGroups?.split(',') || []).map(row => ({ id: row, name: row }))
        case 'ROLE':
          return (element?.businessObject?.candidateGroups?.split(',') || []).map(row => ({ id: row, name: row }))
        case 'POST':
          return (element?.businessObject?.candidateGroups?.split(',') || []).map(row => {
            const arr = row.split('__')
            return {
              id: arr[1],
              name: arr[1],
              type: arr[0]
            }
          })
        case 'DEPT_POST_LEADER':
          return [{ name: '流程发起人部门领导', type: 'DEPT_POST_LEADER' }];
        case 'UP_DEPT_POST_LEADER':
          return [{ name: '流程发起人上级部门领导', type: 'DEPT_POST_LEADER' }];
        case 'CUR_UP_DEPT_POST_LEADER':
          return [{ name: '直属领导', type: 'DEPT_POST_LEADER'}]
        case 'CUR_DEPT_LEADER':
          return [{ name: '部门领导', type: 'DEPT_POST_LEADER'}]
        case 'START_USER_FIRST_LEVEL_DEPT_LEADER_NAME':
          return [{ name: '一级领导', type: 'DEPT_POST_LEADER'}]
        case 'START_USER_SECOND_LEVEL_DEPT_LEADER_NAME':
          return [{ name: '二级领导', type: 'DEPT_POST_LEADER'}]
        case 'START_USER_THIRD_LEVEL_DEPT_LEADER_NAME':
          return [{ name: '三级领导', type: 'DEPT_POST_LEADER'}]
        case 'START_USER_FOUR_LEVEL_DEPT_LEADER_NAME':
          return [{ name: '四级领导', type: 'DEPT_POST_LEADER'}]
        default:
          return []
      }
    },
    // 更新连接线数据
    updateConnectData (row) {
      if (row.conditionStr === '默认流转路径') {
        this.bpmnModeler.get('modeling').updateProperties(row.element.source, {
          default: row.element
        })
      }
      if (row.extensionElements) {
        let hasPriorityLevel = false
        let Properties = null
        row.extensionElements.values.forEach(extension => {
          if (extension.$type === 'flowable:Properties' || extension.$type === 'flowable:properties') {
            Properties = extension
            extension.values.forEach(item => {
              if (item.name === 'priorityLevel') {
                hasPriorityLevel = true
                this.bpmnModeler.get('modeling').updateModdleProperties(extension, item, {
                  value: row.priorityLevel
                })
              }
            })
          }
        })
        if (!hasPriorityLevel) {
          this.bpmnModeler.get('modeling').updateModdleProperties(row.extensionElements, Properties, {
            values: [
              ...Properties.values,
              this.bpmnModeler.get('moddle').create('flowable:Property', {
                name: 'priorityLevel',
                value: row.priorityLevel
              })
            ]
          })
        }
      } else {
        row.extensionElements = this.bpmnModeler.get('moddle').create('bpmn:ExtensionElements', {
          values: [
            this.bpmnModeler.get('moddle').create('flowable:Properties', {
              values: [
                this.bpmnModeler.get('moddle').create('flowable:Property', {
                  name: 'priorityLevel',
                  value: row.priorityLevel
                })
              ]
            })
          ]
        })
      }
      this.bpmnModeler.get('modeling').updateProperties(row.element, {
        extensionElements: row.extensionElements,
        conditionExpression: row.conditionExpression
      })
    },
    // 查找所有子节点
    findNextChild (nodeConfig, childNodes) {
      if (nodeConfig) {
        nodeConfig.element && childNodes.push(nodeConfig.element)
        nodeConfig.endElement && childNodes.push(nodeConfig.endElement)
        if (nodeConfig.childNode) {
          this.findNextChild(nodeConfig.childNode, childNodes)
        }
        if (nodeConfig.conditionNodes) {
          nodeConfig.conditionNodes.forEach(row => {
            this.findNextChild(row.childNode, childNodes)
          })
        }
      }
    },
    getLineConditionNodesClass (index) {
      const colBox = this.$refs.colBox ? this.$refs.colBox[index] : null
      let colBoxLeft = 0
      if (colBox) {
        colBoxLeft = colBox.offsetLeft + colBox.querySelector('.condition-node-box').offsetLeft
      }
      let addBranchLeft = this.$refs.addBranch?.offsetLeft || 0
      return (colBoxLeft - addBranchLeft) < 0 ? 'left' : 'right'
    },
    getConditionNodesLineWidth () {
      const widths = [...this.widths]
      this.nodeConfig.conditionNodes && this.nodeConfig.conditionNodes.forEach((row, index) => {
        const colBox = this.$refs.colBox ? this.$refs.colBox[index] : null
        let colBoxLeft = 0
        if (colBox) {
          colBoxLeft = colBox.offsetLeft + colBox.querySelector('.condition-node-box').offsetLeft
        }
        let addBranchLeft = this.$refs.addBranch?.offsetLeft || 0
        let num = colBoxLeft - addBranchLeft
        widths[index] = (num >= 0 ? colBoxLeft - addBranchLeft + 170 : addBranchLeft - (colBoxLeft + 170))
      })
      this.widths = widths
    },
    isDone (item) {
      let childId = this.nodeConfig.endElement ? this.nodeConfig.endElement.id : this.nodeConfig.element.id;
      const nextChild = (nodeConfig) => {
        if (nodeConfig.childNode) {
          childId = nodeConfig.childNode.endElement ? nodeConfig.childNode.endElement.id : nodeConfig.childNode.element.id;
          nextChild(nodeConfig.childNode)
        }
      }
      nextChild(item)
      return this.finishedTaskSet.indexOf(childId) > -1 && this.unfinishedTaskSet.indexOf(childId) === -1
    }
  }
}
</script>

<style lang="scss">
.node-wrap {
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  padding: 0 50px;
  position: relative;
  vertical-align: middle;
}

.node-wrap-box.error {
  .title {
    background-color: #f56c6c !important;
  }

  .content {
    .placeholder {
      color: #f56c6c;
    }

    background-color: #fef0f0;
  }
}

.node-wrap.success {
  .node-wrap-box {
    border: 0;

    .content {
      border: 1px solid #52B852;
      background-color: #f8fff8 !important;
    }
  }

  &.gateway .node-wrap-box {
    border: 0;
    background-color: #fff;

    .content {
      border: 1px solid #52B852;
      background-color: #f8fff8 !important;
    }
  }
}

.node-wrap.current {
  .node-wrap-box {
    border: 0;

    .content {
      border: 1px solid #62A8FC;
      background-color: #f1f5fe !important;
    }
  }

  &.gateway .node-wrap-box {
    border: 0;
    background-color: #fff;

    .content {
      border: 1px solid #62A8FC;
      background-color: #f1f5fe !important;
    }
  }
}

.node-wrap.start .node-wrap-box .content {
  border: none;
  background-color: #ffffff;
}

.node-wrap.start .node-wrap-box.error .content {
  background-color: #fef0f0;
}

.auto-judge.success {
  border: 1px solid #52B852;
  background-color: #f8fff8 !important;
}

.auto-judge.current {
  border: 1px solid #62A8FC;
  background-color: #f1f5fe !important;
}

.branch-wrap,
.node-wrap {
  display: inline-flex;
  width: 100%;
}

.branch-box-wrap {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 270px;
  width: 100%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.error_tip {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(150%, 0px);
  font-size: 24px;
}

.promoter_person .el-dialog__body {
  padding: 10px 20px 14px 20px;
}

.selected_list {
  margin-bottom: 20px;
  line-height: 30px;
}

.selected_list span {
  margin-right: 10px;
  padding: 3px 6px 3px 9px;
  line-height: 12px;
  white-space: nowrap;
  border-radius: 2px;
  border: 1px solid rgba(220, 220, 220, 1);
}

.selected_list img {
  margin-left: 5px;
  width: 7px;
  height: 7px;
  cursor: pointer;
}

.auto-judge:hover .editable-title.editing,
.node-wrap-box:hover .editable-title.editing {
  text-decoration: none;
  border: 1px solid #d9d9d9;
}

.auto-judge:hover .editable-title {
  border-color: #15bc83;
}

.priority-title {
  color: #999999;
  font-size: 12px;
  padding-right: 10px;
}

.node-wrap-box {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  width: 220px;
  min-height: 72px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.node-wrap-box:after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.node-wrap-box.active:after,
.node-wrap-box:active:after,
.node-wrap-box:hover:after {
  border: 1px solid #3296fa;
  box-shadow: 0 0 6px 0 rgba(50, 150, 250, 0.3);
}

.node-wrap-box.active .close,
.node-wrap-box:active .close,
.node-wrap-box:hover .close {
  display: block;
}

.node-wrap-box .title {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 30px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  color: #fff;
  text-align: left;
  background: #FFA455;
  border-radius: 4px 4px 0 0;
}

.node-wrap-box .title .iconfont {
  font-size: 12px;
  margin-right: 5px;
}

.node-wrap-box .placeholder {
  color: #333333;
}

.node-wrap-box .close {
  display: none;
  position: absolute;
  right: 10px;
  top: 0;
  // transform: translateY(-50%);
  width: 20px;
  height: 20px;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
}

.node-wrap-box .content {
  position: relative;
  font-size: 14px;
  padding: 16px 30px 16px 16px;
  z-index: 2;
}

.node-wrap-box .content .text {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #333333;
}

.node-wrap-box .content .arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 14px;
  font-size: 14px;
  color: #979797;
}

.start-node.node-wrap-box .content .text {
  display: block;
  white-space: nowrap;
  color: #333333;
}

.node-wrap-box:before {
  content: "";
  position: absolute;
  top: -12px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
  height: 4px;
  border-style: solid;
  border-width: 8px 6px 4px;
  border-color: #D9DBDD transparent transparent;
  background: #f5f5f7;
}

.success .node-wrap-box:before, .current .node-wrap-box:before {
  border-color: #999999 transparent transparent;
}

.node-wrap-box.start-node:before {
  content: none;
}

.branch-box-wrap {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 270px;
  width: 100%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.branch-box {
  display: flex;
  overflow: visible;
  min-height: 180px;
  height: auto;
  border-bottom: 2px solid #D9DBDD;
  border-top: 2px solid #D9DBDD;
  position: relative;
  // margin-top: 15px;
}

.branch-box .col-box {
  background: #f5f5f7;
  position: relative;
}

.top-line, .end-line {
  position: absolute;
  height: 2px;
  background-color: #999999;
  display: none;
}

.active > .top-line {
  display: block;
  top: -2px;
}

.left {
  &.top-line, &.end-line {
    left: 50%;
  }
}

.right {
  &.top-line, &.end-line {
    right: 50%;
  }
}

.active > .end-line {
  bottom: -2px;
}

.branch-box .col-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  margin: auto;
  width: 2px;
  height: 100%;
  background-color: #D9DBDD;
}

.branch-box .done.active.col-box:before {
  background-color: #999999;
}
.branch-box .done .end-line{
  display: block;
}

.box-scale {
  transform: scale(1);
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 54.5px 0;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  background-color: #f5f5f7;
  transform-origin: 50% 0px 0px;
}

.add-branch {
  border: none;
  outline: none;
  user-select: none;
  justify-content: center;
  font-size: 12px;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  color: #333333;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center center;
  cursor: pointer;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.add-branch.readonly {
  // display: none;
  opacity: 0;
}

.add-branch:hover {
  transform: translateX(-50%) scale(1.1);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}

.add-branch:active {
  transform: translateX(-50%);
  box-shadow: none;
}

.col-box {
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
}

.condition-node {
  min-height: 220px;
}

.condition-node,
.condition-node-box {
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
}

.condition-node-box {
  padding-top: 30px;
  padding-right: 50px;
  padding-left: 50px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.condition-node-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 2px;
  height: 100%;
  background-color: #D9DBDD;
}

.active > .condition-node > .condition-node-box:before {
  background-color: #999999;
}

.auto-judge {
  position: relative;
  width: 220px;
  min-height: 72px;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.auto-judge:after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.auto-judge.active:after,
.auto-judge:active:after,
.auto-judge:hover:after {
  border: 1px solid #3296fa;
  box-shadow: 0 0 6px 0 rgba(50, 150, 250, 0.3);
}

.auto-judge.active .close,
.auto-judge:active .close,
.auto-judge:hover .close {
  display: block;
}

.auto-judge.error:after {
  border: 1px solid #f25643;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.auto-judge .title-wrapper {
  position: relative;
  font-size: 12px;
  color: #15bc83;
  border-bottom: 1px solid #E8E8E8;
  padding: 0 19px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: content-box;
}

.auto-judge .title-wrapper .editable-title {
  display: inline-block;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.auto-judge .placeholder {
  color: #bfbfbf;
}

.auto-judge .close {
  display: none;
  position: absolute;
  right: 8px;
  top: 0px;
  width: 20px;
  height: 30px;
  font-size: 14px;
  color: #999999;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  z-index: 2;
}

.auto-judge .content {
  font-size: 14px;
  color: #333333;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding: 14px 19px 20px;
  position: relative;
  z-index: 2;
}

.auto-judge .sort-left,
.auto-judge .sort-right {
  position: absolute;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 1;
}

.auto-judge .sort-left {
  left: 0;
  border-right: 1px solid #f6f6f6;
}

.auto-judge .sort-right {
  right: 0;
  border-left: 1px solid #f6f6f6;
}

.auto-judge:hover .sort-left,
.auto-judge:hover .sort-right {
  display: flex;
  align-items: center;
}

.auto-judge .sort-left:hover,
.auto-judge .sort-right:hover {
  background: #efefef;
}

.end-node {
  border-radius: 50%;
  font-size: 14px;
  color: #999999;
  text-align: left;
}

.end-node .end-node-circle {
  width: 10px;
  height: 10px;
  margin: auto;
  border-radius: 50%;
  background: #dbdcdc;
}

.end-node .end-node-text {
  margin-top: 5px;
  text-align: center;
}

.editable-title {
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // border-bottom: 1px dashed transparent;
}

.editable-title:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 40px;
}

.editable-title-input {
  flex: none;
  height: 18px;
  padding-left: 4px;
  text-indent: 0;
  font-size: 12px;
  line-height: 18px;
  z-index: 1;
}

.editable-title-input:hover {
  text-decoration: none;
}

.ant-btn {
  position: relative;
}

.top-left-cover-line {
  left: -1px;
}

.top-left-cover-line,
.top-right-cover-line {
  position: absolute;
  height: 8px;
  width: 50%;
  background-color: #f5f5f7;
  top: -4px;
}

.top-right-cover-line {
  right: -1px;
}

.bottom-left-cover-line {
  left: -1px;
}

.bottom-left-cover-line,
.bottom-right-cover-line {
  position: absolute;
  height: 8px;
  width: 50%;
  background-color: #f5f5f7;
  bottom: -4px;
}

.bottom-right-cover-line {
  right: -1px;
}
</style>
