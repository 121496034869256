
const state = {
  // 临时缓存 所有编辑表单
  allForms: [],
  // 缓存AI生成的评论
  commits: localStorage.getItem('commits') || {}
}

const mutations = {
  CLEARFORMS (state) {
    state.allForms = []
  },
  SAVEFORMS (state, data) {
    state.allForms = data
  }
}

const actions = {
  clearForms ({commit}) {
    commit('CLEARFORMS')
  },
  saveForms ({commit}, data) {
    commit('SAVEFORMS', data)
  },
  saveCommits ({state}, {key, data}) {
    state.commits[key] = data
    localStorage.setItem('commits', JSON.stringify(state.commits))
  },
  readCacheCommits ({state}) {
    return state.commits
  },
  removeRecode ({state}, key) {
    delete state.commits[key]
    localStorage.setItem('commits', JSON.stringify(state.commits))
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
