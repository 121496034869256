<template>
  <!-- <div class="uditor-class" ref="editor"></div> -->
  <div style="border: 1px solid #ccc;">
        <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <Editor
            style="height: 500px; overflow-y: hidden;"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
        />
    </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  components: { Editor, Toolbar },
  props: {
    /**
     * 绑定字段
     */
    value: {
      type: String
    }
  },
  data () {
    return {
      editor: null,
      toolbarConfig: {
        excludeKeys: ['group-video']
      },
      editorConfig: {
        placeholder: '',
        MENU_CONF: {
          uploadImage: {
            server: process.env.VUE_APP_NUXT_API + '/upload/',
            base64LimitSize: 10 * 1024
          }
        }
      },
      mode: 'default', // or 'simple',
      html: ''
    }
  },
  created () {
    this.html = this.value
  },
  methods: {
    onCreated (editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    getHtml () {
      return this.editor.getHtml()
    }
  },

  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  watch: {
    html (newVal, oldVal) {
      this.$emit('input', newVal);
    }
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css">
</style>
