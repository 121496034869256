export class EsignController {
  static list (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/nuxtapi/eFlow/list', 'post', params, axiosOption, httpOption);
  }

  static revoke (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/nuxtapi/eFlow/revoke', 'post', params, axiosOption, httpOption);
  }
  static preview (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/nuxtapi/eFlow/preview', 'GET', params, axiosOption, httpOption);
  }

  static reinvoke (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/nuxtapi/eFlow/reinvoke', 'GET', params, axiosOption, httpOption);
  }

  static delete (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/nuxtapi/eFlow/delete', 'POST', params, axiosOption, httpOption);
  }
}
