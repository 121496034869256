<template>
  <div class="add-node-btn-box" :key="id">
    <div class="add-node-btn">
      <el-popover placement="right-start" v-model="visible" v-if="!readonly">
        <div class="add-node-popover-body">
          <a class="add-node-popover-item approver" @click="addType(FlowNodeType.APPROVED_BY)">
            <div class="item-wrapper">
              <img class="iconfont" src="@/assets/img/spjd.png" alt="">
              <img class="iconfont" src="@/assets/img/spjd2.png" alt="">
            </div>
            <p>审批人</p>
          </a>
          <!--<a class="add-node-popover-item notifier" @click="addType(FlowNodeType.CC_TO)">-->
          <!--  <div class="item-wrapper">-->
          <!--    <span class="iconfont"></span>-->
          <!--  </div>-->
          <!--  <p>抄送人</p>-->
          <!--</a>-->
          <a class="add-node-popover-item condition" @click="addType(FlowNodeType.CONDITIONAL_BRANCH)">
            <div class="item-wrapper">
              <img class="iconfont" src="@/assets/img/tj.png" alt="">
              <img class="iconfont" src="@/assets/img/tj2.png" alt="">
            </div>
            <p>条件分支</p>
          </a>
          <a class="add-node-popover-item condition" @click="addType(FlowNodeType.PARALLEL_BRANCH)">
            <div class="item-wrapper">
              <img class="iconfont" src="@/assets/img/wg.png" alt="">
              <img class="iconfont" src="@/assets/img/wg2.png" alt="">
            </div>
            <p>并行分支</p>
          </a>
        </div>
        <div class="btn" type="button" slot="reference" v-if="!isGateway">
          <i class="el-icon-plus"></i>
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
export default {
  props: ['childNodeP', 'priorityLevel', 'isEnd', 'isGateway', 'nodeConfig'],
  data () {
    return {
      visible: false,
      id: Date.now()
    }
  },
  inject: ['bpmnModeler', 'readonly'],
  mounted () {
  },
  methods: {
    addType (type) {
      this.visible = false
      const StartEvent = this.bpmnModeler.get('elementRegistry').find(el => el.type === 'bpmn:StartEvent')
      // 添加审批人
      if (type === this.FlowNodeType.APPROVED_BY) {
        // 创建用户任务节点
        const UserTask = this.bpmnModeler.get('elementFactory').createShape({ type: 'bpmn:UserTask' })
        // 插入新建的用户任务节点
        this.bpmnModeler.get('autoPlace').append(StartEvent, UserTask)
        let data = {
          nodeName: '审核人',
          error: true,
          type: this.FlowNodeType.APPROVED_BY,
          element: UserTask,
          childNode: this.childNodeP,
          nodeUserList: []
        }
        this.$emit('update:childNodeP', data)
      } else {
        // 添加分支
        let data = {
          nodeName: type === this.FlowNodeType.CONDITIONAL_BRANCH ? '网关' : '并行网关',
          type: type,
          childNode: this.childNodeP ? this.childNodeP : null,
          element: null,
          conditionNodes: [
            {
              nodeName: '',
              type: this.FlowNodeType.CONNECTING_LINE,
              element: null,
              priorityLevel: 0,
              conditionStr: type === this.FlowNodeType.CONDITIONAL_BRANCH ? '普通流转路径' : '并行任务（同时进行）',
              nodeUserList: [],
              childNode: null
            },
            {
              nodeName: '',
              type: this.FlowNodeType.CONNECTING_LINE,
              element: null,
              priorityLevel: 1,
              conditionStr: type === this.FlowNodeType.CONDITIONAL_BRANCH ? '普通流转路径' : '并行任务（同时进行）',
              nodeUserList: [],
              childNode: null
            }
          ]
        }
        // 创建分支节点
        data.element = this.bpmnModeler.get('elementFactory').createShape({ type: type === this.FlowNodeType.CONDITIONAL_BRANCH ? 'bpmn:ExclusiveGateway' : 'bpmn:ParallelGateway' })
        data.endElement = this.bpmnModeler.get('elementFactory').createShape({ type: type === this.FlowNodeType.CONDITIONAL_BRANCH ? 'bpmn:ExclusiveGateway' : 'bpmn:ParallelGateway' })

        // 插入新建的用户任务节点
        this.bpmnModeler.get('autoPlace').append(StartEvent, data.element)
        this.bpmnModeler.get('autoPlace').append(data.element, data.endElement)
        this.bpmnModeler.get('modeling').removeElements(data.element.outgoing)
        data.conditionNodes.forEach(row => {
          row.element = this.bpmnModeler.get('modeling').connect(data.element, data.endElement)
          this.updateConnectData(row)
        })
        this.$emit('update:childNodeP', data)
      }
    },
    // 更新连接线数据
    updateConnectData (row) {
      if (row.conditionStr === '默认流转路径') {
        this.bpmnModeler.get('modeling').updateProperties(row.element.source, {
          default: row.element
        })
      }

      if (row.extensionElements) {
        let hasPriorityLevel = false
        let Properties = null
        row.extensionElements.values.forEach(extension => {
          if (extension.$type === 'flowable:Properties' || extension.$type === 'flowable:properties') {
            Properties = extension
            extension.values.forEach(item => {
              if (item.name === 'priorityLevel') {
                hasPriorityLevel = true
                this.bpmnModeler.get('modeling').updateModdleProperties(extension, item, {
                  value: row.priorityLevel
                })
              }
            })
          }
        })
        if (!hasPriorityLevel) {
          this.bpmnModeler.get('modeling').updateModdleProperties(row.extensionElements, Properties, {
            values: [
              ...Properties.values,
              this.bpmnModeler.get('moddle').create('flowable:Property', {
                name: 'priorityLevel',
                value: row.priorityLevel
              })
            ]
          })
        }
      } else {
        row.extensionElements = this.bpmnModeler.get('moddle').create('bpmn:ExtensionElements', {
          values: [
            this.bpmnModeler.get('moddle').create('flowable:Properties', {
              values: [
                this.bpmnModeler.get('moddle').create('flowable:Property', {
                  name: 'priorityLevel',
                  value: row.priorityLevel
                })
              ]
            })
          ]
        })
      }
      this.bpmnModeler.get('modeling').updateProperties(row.element, {
        extensionElements: row.extensionElements,
        conditionExpression: row.conditionExpression
      })
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/style/element-variables.scss';
.add-node-btn-box {
  width: 240px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    margin: auto;
    width: 2px;
    height: 100%;
    background-color: #D9DBDD;
  }

  .add-node-btn {
    user-select: none;
    width: 240px;
    padding: 20px 0 32px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;

    .btn {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      width: 28px;
      height: 28px;
      background: white;
      border: 1px solid #D9DBDD;
      border-radius: 50%;
      position: relative;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      display: flex;
      justify-content: center;
      align-items: center;

      .el-icon-plus {
        color: #333333;
        width: 16px;
        font-size: 16px;
        font-weight: bold;
      }

      &:hover {
        transform: scale(1.3);
        box-shadow: 0 13px 27px 0 rgba(0, 0, 0, 0.1);
      }

      &:active {
        transform: none;
        background: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.success .add-node-btn-box, .add-node-btn-box.active {
  &:before {
    background-color: #999999;
    z-index: 0;
  }
}
.current .add-node-btn-box {
  &:before {
    background-color: #cacaca;
    z-index: -1;
  }
}
</style>
<style lang="scss">
@import '@/assets/style/element-variables.scss';
.add-node-popover-body {
  display: flex;

  .add-node-popover-item {
    margin: 0 8px;
    cursor: pointer;
    text-align: center;
    flex: 1;
    color: #333333 !important;

    .item-wrapper {
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      margin-bottom: 5px;
      background: #fff;
      border: 1px solid #e2e2e2;
      border-radius: 50%;
      // transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      .iconfont {
        width: 35px;
        font-size: 35px;
        line-height: 80px;

        &:nth-child(2) {
          display: none;
        }
      }
    }

    p {
      margin-bottom: 0;
    }

    &.approver {
      .item-wrapper {
        color: #ff943e;
      }
    }

    &.notifier {
      .item-wrapper {
        color: #3296fa;
      }
    }

    &.condition {
      .item-wrapper {
        color: #15bc83;
      }
    }

    &:hover {
      .item-wrapper {
        background: $--color-primary;
        box-shadow: 0px 2px 8px 1px rgba(255,119,0,0.3);

        .iconfont {
          &:nth-child(1) {
            display: none;
          }

          &:nth-child(2) {
            display: block;
          }
        }
      }

      .iconfont {
        color: #fff;
      }
    }

    &:active {
      .item-wrapper {
        box-shadow: none;
        background: #D9DBDD;
      }

      .iconfont {
        color: inherit;
      }
    }
  }
}
</style>
