var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"el-input el-date-editor el-range-editor el-input__inner el-input-number-range",class:[
    _vm.inputSize ? 'el-range-editor--' + _vm.inputSize : '',
    _vm.focused ? 'is-active' : '',
    {
      'is-disabled': _vm.inputDisabled,
      'el-input--prefix': _vm.prefixIcon
    }
  ],on:{"mouseenter":function($event){_vm.showClose = true},"mouseleave":function($event){_vm.showClose = false}}},[_c('div',{staticClass:"el-input__icon el-range__icon",class:_vm.prefixIcon},[_vm._t("prepend")],2),_c('input',{staticClass:"el-range-input",attrs:{"autocomplete":"off","placeholder":_vm.startPlaceholder,"disabled":_vm.inputDisabled,"readonly":_vm.readonly,"name":_vm.name && _vm.name[0]},domProps:{"value":_vm.userInput && _vm.userInput[0]},on:{"input":_vm.handleStartInput,"change":_vm.handleStartChange,"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false}}}),_vm._t("range-separator",function(){return [_c('span',{staticClass:"el-range-separator"},[_vm._v(_vm._s(_vm.rangeSeparator))])]}),_c('input',{staticClass:"el-range-input",attrs:{"autocomplete":"off","placeholder":_vm.endPlaceholder,"disabled":_vm.inputDisabled,"readonly":_vm.readonly,"name":_vm.name && _vm.name[1]},domProps:{"value":_vm.userInput && _vm.userInput[1]},on:{"input":_vm.handleEndInput,"change":_vm.handleEndChange,"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false}}}),_c('i',{staticClass:"el-input__icon el-range__close-icon",class:[_vm.showClear ? 'el-icon-circle-close' : ''],on:{"click":_vm.handleClickClear}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }