export class SealController {
  static list (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/nuxtapi/seal/list', 'get', params, axiosOption, httpOption);
  }

  // static view (sender, params, axiosOption, httpOption) {
  //   return sender.doUrl('/seal/view', 'get', params, axiosOption, httpOption);
  // }

  static create (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/nuxtapi/seal/create', 'post', params, axiosOption, httpOption);
  }

  static update (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/nuxtapi/seal/update', 'post', params, axiosOption, httpOption);
  }

  static delete (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/nuxtapi/seal/delete', 'post', params, axiosOption, httpOption);
  }
}
