const state = {
  flow: {},
  // 品牌
  brands: [],
  // 类目
  category: [],
  // 供应商
  supplier: [],
  // 数据库
  tables: new Map(),
  // 权限列表
  permissions: getFromLocalstorage('permissions') || [],
  // 所有用户的字典
  userDict: getFromLocalstorage('bpmusers') || new Map()
};

const mutations = {
  SET_FLOW (state, data) {
    state.flow = data;
  },
  SET_BRANDS (state, data) {
    state.brands = data
  },
  SET_CATEGORY (state, data) {
    state.category = data
  },
  SET_SUPPLIER (state, data) {
    state.supplier = data
  },
  SET_TABLES (state, key, data) {
    state.tables.set('key', data)
  },
  SET_PERMISSIONS (state, data) {
    state.permissions = data
    saveLocal('permissions', data)
  },
  SET_USERS (state, data) {
    state.userDict = data
    saveLocal('bpmusers', data)
  }
};

const actions = {
  setFlowData ({ commit }, data) {
    commit('SET_FLOW', data);
  },
  saveCategory ({commit}, data) {
    commit('SET_CATEGORY', data)
  },
  saveBrands ({commit}, data) {
    commit('SET_BRANDS', data)
  },
  saveSupplier ({commit}, data) {
    commit('SET_SUPPLIER', data)
  },
  saveTables ({commit}, key, data) {
    commit('SET_TABLES', key, data)
  },
  savePermissions ({commit}, data) {
    commit('SET_PERMISSIONS', data)
  },
  saveUserList ({commit}, data) {
    commit('SET_USERS', data)
  }
};
const getters = {
  isFlow (state) {
    return !!state.flow.entryId
  }
};

function saveLocal (key, data) {
  localStorage.setItem(key, JSON.stringify(data))
}

function getFromLocalstorage (key) {
  return JSON.parse(localStorage.getItem(key))
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
