export class MccBatchProductController {
  static list (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/business/mccBatchProduct/list', 'post', params, axiosOption, httpOption);
  }

  static view (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/business/mccBatchProduct/view', 'get', params, axiosOption, httpOption);
  }

  static export (sender, params, fileName) {
    return sender.download('/admin/business/mccBatchProduct/export', params, fileName);
  }

  static startOnlyCpsp (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/business/mccBatchProduct/startOnly/cpsp', 'post', params, axiosOption, httpOption);
  }

  static startWithBusinessKeyCpsp (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/business/mccBatchProduct/startWithBusinessKey/cpsp', 'post', params, axiosOption, httpOption);
  }

  static startAndTakeUserTaskCpsp (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/business/mccBatchProduct/startAndTakeUserTask/cpsp', 'post', params, axiosOption, httpOption);
  }

  static startAndSaveDraftCpsp (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/business/mccBatchProduct/startAndSaveDraft/cpsp', 'post', params, axiosOption, httpOption);
  }

  static submitUserTaskCpsp (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/business/mccBatchProduct/submitUserTask/cpsp', 'post', params, axiosOption, httpOption);
  }

  static viewTaskBusinessDataCpsp (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/business/mccBatchProduct/viewTaskBusinessData/cpsp', 'get', params, axiosOption, httpOption);
  }

  static viewHistoricTaskBusinessDataCpsp (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/business/mccBatchProduct/viewHistoricTaskBusinessData/cpsp', 'get', params, axiosOption, httpOption);
  }

  static listWorkOrderCpsp (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/business/mccBatchProduct/listWorkOrder/cpsp', 'post', params, axiosOption, httpOption);
  }
}
