import store from '@/store';

/**
 * 处理 过滤参数
 * @param {Array} filters 过滤参数数组
 * @param {String} variableName
 * @param {String} tableName
 * @param {Object} formData
 * @param {Object} params 从父级表单 传递的参数
 */
// preitter-ignore
export function dealParams (filters, variableName, tableName, formData, params) {
  const userInfo = store.getters.getUserInfo;
  let filterDtoList = [];
  let masterData = formData[variableName]
  for (const rule of filters) {
    // 处理 数据过滤规则
    if (rule.useVar) {
      if (params) {
        masterData = {...masterData, ...params}
      }
      // 需要根据 变量类型取值
      switch (rule.type) {
        case '0':
          filterDtoList.push(
            dealFilterType(
              tableName,
              rule.key,
              masterData[rule.value],
              rule.filterType,
              rule.rela
            )
          );
          break;
        case '1':
          filterDtoList.push(
            dealFilterType(
              tableName,
              rule.key,
              userInfo[rule.value],
              rule.filterType,
              rule.rela
            )
          );
          break;
        case '2':
          filterDtoList.push(
            dealFilterType(
              tableName,
              rule.key,
              formData.customField[rule.value],
              rule.filterType,
              rule.rela
            )
          );
          break;
        default:
          break;
      }
    } else {
      filterDtoList.push(
        dealFilterType(
          tableName,
          rule.key,
          rule.value,
          rule.filterType,
          rule.rela
        )
      );
    }
  }
  return filterDtoList;
}
/**
 * 根据 参数过滤类型 构造 参数
 * @param {*} tableName
 * @param {*} key
 * @param {*} value
 * @param {*} type
 */
export function dealFilterType (tableName, key, value, type = 1, isOR = false) {
  switch (type) {
    case 4:
    case 6:
      return {
        tableName,
        columnName: key,
        columnValue: value.split(','),
        columnValueList: value.split(','),
        filterType: type,
        orTrue: isOR
      };
    case 7:
    case 8:
      return {
        tableName,
        columnValue: '不需要传值非要我传',
        columnName: key,
        filterType: type,
        orTrue: isOR
      };
    case 1:
    case 3:
    case 9:
    case 10:
    case 11:
    default:
      return {
        tableName,
        columnName: key,
        columnValue: value,
        filterType: type,
        orTrue: isOR
      };
  }
}
