<template>
  <div>
    <el-button class="w-full" :loading="loading" :type="widget.props.buttonType"  :icon="widget.props.preIcon" @click="doAction">{{ widget.props.buttonText }}</el-button>
  </div>
</template>

<script>
export default {
  props: {
    widget: {
      type: Object,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false
    };
  },
  inject: ['form'],
  created () {
  },
  methods: {
    doAction () {
      // // 编辑模式下 不起作用
      if (this.isEdit) return
      this.form().executeEvent(this.widget.props.doScript)
    }
  }
};
</script>

<style scoped lang="scss">
.w-full{
  width: 100%;
}
</style>
