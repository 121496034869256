<template>
  <div class="p-30 wrapper" ref="formWrapper">
    <el-row>
      <el-col>
        <div class="flex flex-between m-b-20">
          <div class="flex-1">
            <div class="flex flex-start" v-if="filterColumn.length > 0">
              <el-select v-model="filterNameIndex" placeholder="请选择检索字段" class="m-r-10">
                <el-option v-for="item in filterColumn" :key="item.columnName" :label="item.columnComment || item.columnName" :value="item.columnName"></el-option>
              </el-select>
              <el-input class="m-r-10" v-model="filterName" type="search" placeholder="" style="width: 200px"></el-input>
              <el-button type="primary" @click="queryByParam()">查询</el-button>
            </div>
          </div>
          <el-button type="primary" @click="onSubmit" class="btn-confirm"> 确定 </el-button>
        </div>
      </el-col>
    </el-row>
    <vxe-table ref="table"
      :data="dataList" border
      :height="tableHeight"
      :seq-config="{startIndex: (page.currentPage - 1) * page.pageSize}"
      :checkbox-config="{reserve: true}"
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent">
      <vxe-column type="checkbox" width="60"></vxe-column>
      <vxe-column type="seq" title="序号" :width="50" />
      <vxe-column align="center" v-for="item in head" :key="item.columnName" :title="item.columnComment">
        <template #default="{ row }">
          {{ row[item.columnName] }}
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-pager
      :current-page.sync="page.currentPage"
      :page-size.sync="page.pageSize"
      size="mini"
      :total="page.total"
      :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']"
      @page-change="queryByParam"
    >
    </vxe-pager>
  </div>
</template>

<script>
import { OnlineFormController } from '@/api/OnlineFormController'

export default {
  props: {
    showColumn: {
      type: Array,
      default: () => []
    },
    //
    innerDataList: {
      type: Array,
      default: () => []
    },
    dblinkId: {
      type: [String, Number],
      default: ''
    },
    masterTableId: {
      type: [String, Number],
      default: ''
    },
    /** 自定义的主键字段名 */
    customerPrimaryKey: {
      type: String,
      default: ''
    },
    /** 数据筛选规则 */
    rule: {
      type: Array,
      default: () => []
    },
    mappings: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dataList: [],
      filterNameIndex: '',
      filterName: '',
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      tableHeight: '500px',
      head: [], // 表头
      checkedList: [],
      primaryKey: '',
      mappingKey: ''
    }
  },
  computed: {
    filterColumn () {
      return this.showColumn.filter(m => m.isFilter)
    }
  },
  created () {
    this.queryByParam()
    // 如果没有自定义主键字段 则取默认主键, 根据自定义主键 在映射关系中找到 对应的键
    if (!this.customerPrimaryKey) {
      this.customerPrimaryKey = this.showColumn.find(m => m.primaryKey).columnName
    }
    if (this.filterColumn.length > 0) this.filterNameIndex = this.filterColumn[0].columnName
    try {
      // mappingKey 为 映射的字段
      // customerPrimaryKey 为自定义的主键
      // 因此 primaryKey 为 数据的原始字段名
      this.primaryKey = this.mappings.find(m => m.mappingKey === this.customerPrimaryKey).columnName
    } catch (error) {
      this.$message.error('疑似未配置主键映射字段, 请检查字段映射关系')
      console.log('疑似未配置主键映射字段, 请检查字段映射关系', error)
    }
  },
  methods: {
    selectAllEvent (e) {
      this.checkedList = e.records
    },
    selectChangeEvent (e) {
      this.checkedList = e.records
    },
    queryByParam (page) {
      if (page) this.page = page
      let param = {
        dbLinkId: this.dblinkId,
        tableName: this.masterTableId,
        pageParam: {
          pageNum: this.page.currentPage,
          pageSize: this.page.pageSize
        }
      }
      if (this.filterName) {
        param.filterDtoList = [
          {
            columnName: this.filterNameIndex,
            columnValue: this.filterName,
            filterType: 3
          }
        ]
      }
      if (this.rule.length > 0) {
        if (!param.filterDtoList) param.filterDtoList = []
        param.filterDtoList = param.filterDtoList.concat(this.rule)
      }
      OnlineFormController.listByDbLinkId(this, param).then((res) => {
        res.data.head.forEach((m) => {
          if (!m.columnComment) m.columnComment = m.columnName
        })
        this.head = this.showColumn.filter(m => m.show) // res.data.head

        this.dataList = res.data.dataList.data
        // 根据已有项  勾选
        const primaryKey = this.primaryKey
        // 初始化一个与主键同样的 键
        let mapKey = primaryKey
        this.$nextTick(() => {
          // 根据已选项 勾选多选数据, 需要判断是否为映射字段
          if (this.mappings.find(m => primaryKey === m.columnName)) {
            mapKey = this.mappings.find(m => primaryKey === m.columnName).mappingKey
            this.mappingKey = mapKey
          }
          this.innerDataList.forEach(m => {
            /* eslint-disable-next-line */
            const index = this.dataList.findIndex(item => item[primaryKey] == m[mapKey])
            if (index > -1) {
              this.$refs.table.setCheckboxRow(this.dataList[index], true)
            }
          })
        })

        this.page.total = res.data.dataList.totalCount
        this.$forceUpdate()
      })
    },
    onSubmit () {
      if (this.observer != null) {
        const cur = this.$refs.table.getCheckboxRecords(true)
        const checkInHiddenPage = this.$refs.table.getCheckboxReserveRecords(true)
        console.log(cur, checkInHiddenPage);
        this.observer.cancel(true, [...cur, ...checkInHiddenPage])
      } else {
        this.$message.error('逻辑问题')
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.tableHeight = this.$refs.formWrapper.offsetHeight - 164 + 'px'
    })
  }
}
</script>

<style scoped lang="scss">
.wrapper{
  height: 100%;
}
</style>
