<template>
  <el-row type="flex" :justify="align">
    <img :src="imageUrl" :style="getStyle" />
  </el-row>
</template>

<script>
import { uploadMixin } from '@/core/mixins';

export default {
  name: 'onlineCustomImage',
  props: {
    value: {
      type: String
    },
    src: {
      type: String
    },
    fit: {
      type: String
    },
    align: {
      type: String,
      default: 'left'
    },
    width: {
      type: String
    },
    height: {
      type: String
    },
    radius: {
      type: Number,
      default: 5
    },
    round: {
      type: Boolean,
      default: false
    },
    widget: {
      type: Object
    }
  },
  mixins: [uploadMixin],
  inject: ['form'],
  data () {
    return {
      fileList: []
    }
  },
  computed: {
    getStyle () {
      let temp = this.round ? '50%' : (this.radius + 'px');
      return {
        width: this.width != null ? this.width : '200px',
        height: this.height != null ? this.height : '200px',
        'object-fit': this.fit,
        'border-radius': temp
      }
    },
    buildFlowParam () {
      let flowParam = {};
      let flowData = this.form().flowData;
      if (flowData) {
        if (flowData.processDefinitionKey) flowParam.processDefinitionKey = flowData.processDefinitionKey;
        if (flowData.processInstanceId) flowParam.processInstanceId = flowData.processInstanceId;
        if (flowData.taskId) flowParam.taskId = flowData.taskId;
      }

      return flowParam;
    },
    imageUrl () {
      if (Array.isArray(this.fileList) && this.fileList.length > 0) {
        return this.fileList[0].url;
      } else {
        return this.src;
      }
    }
  },
  watch: {
    value: {
      handler (newValue) {
        if (Array.isArray(newValue)) {
          newValue.forEach(m => {
            delete m.uid
            delete m.status
          })
        }
        if (typeof newValue === 'string') {
          try {
            newValue = JSON.parse(newValue)
          } catch (error) {
            console.log(newValue)
          }
        }
        this.fileList = newValue || []
      },
      immediate: true
    }
  }
}
</script>

<style>
</style>
