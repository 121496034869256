export class ProxyOrderController {
  // 导入excel文件
  static importExcel (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelItem/v1/import', 'post', params, axiosOption, httpOption);
  }

  static removeData (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelItem/v1/delete', 'post', params, axiosOption, httpOption);
  }

  // 更新关联商品信息
  static update (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelItem/v1/update', 'post', params, axiosOption, httpOption);
  }

  static list (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelItem/v1/page', 'post', params, axiosOption, httpOption);
  }

  // 查询客户信息
  static findcustomer (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelImportOrder/v1/customerList', 'post', params, axiosOption, httpOption);
  }

  // 导入列表
  static importExcelList (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelImportOrder/v1/page', 'post', params, axiosOption, httpOption);
  }

  static orderDetail (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelImportOrder/v1/detail', 'post', params, axiosOption, httpOption);
  }

  // 导入订单
  static importOrderExcel (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelImportOrder/v1/importOrder', 'post', params, axiosOption, httpOption);
  }

  // 查询商品列表
  static findGoods (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelItem/v1/itemList', 'post', params, axiosOption, httpOption);
  }

  // 商品绑定
  static bindGoods (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelItem/v1/add', 'post', params, axiosOption, httpOption);
  }

  // 重新导入订单
  static reImportOrder (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelImportOrder/v1/reImportOrder', 'post', params, axiosOption, httpOption);
  }

  // 获取系统字段
  static getSystemField (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelTemplate/v1/fieldTemplateList', 'post', params, axiosOption, httpOption);
  }

  // 保存模板数据
  static saveTemplate (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelTemplate/v1/saveTemplate', 'post', params, axiosOption, httpOption);
  }

  // 组织字典
  static getOrgsDict (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelImportOrder/v1/dictList', 'post', params, axiosOption, httpOption);
  }

  // 查询模版列表
  static getTemplateList (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelTemplate/v1/page', 'post', params, axiosOption, httpOption);
  }

  // 更新模版
  static updateTemplate (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelTemplate/v1/updateTemplate', 'post', params, axiosOption, httpOption);
  }

  // 获取模版详情
  static getTemplateDetail (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelTemplate/v1/detail', 'post', params, axiosOption, httpOption);
  }

  // 删除模板
  static deleteTemplate (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelTemplate/v1/remove', 'post', params, axiosOption, httpOption);
  }

  // 删除导入的订单
  static deleteImportOrder (sender, params, axiosOption, httpOption) {
    return sender.doUrl('/admin/upms/excelImportOrder/v1/remove', 'post', params, axiosOption, httpOption);
  }
}
