<template>
  <div class="online-custom-upload">
    <el-upload v-if="!readOnly" :class="{
        'upload-image-item': isImage,
        'upload-image-multi': maxFileCount !== 1
      }"
      name="uploadFile"
      :headers="getUploadHeaders"
      :action="actionUrl"
      multiple
      :on-change="handlerFileChange"
      :on-remove="onRemoveFile"
      :on-error="onUploadError"
      :on-exceed="onUploadLimit"
      :limit="maxFileCount"
      :show-file-list="maxFileCount !== 1 || !isImage"
      :on-preview="previewFile"
      :list-type="getUploadListType"
      :file-list="getUploadFileList"
      :disabled="getDisabledStatus()">
      <!-- 上传图片 -->
      <template v-if="isImage && maxFileCount === 1">
        <div v-if="getUploadFileList && getUploadFileList[0] != null" style="position: relative">
          <img class="upload-image-show" :src="getUploadFileList[0].url" />
          <div class="upload-img-del el-icon-close" @click.stop="onRemoveFile(null, null)" />
        </div>
        <i v-else class="el-icon-plus upload-image-item"></i>
      </template>
      <!-- 上传文件 -->
      <template v-else-if="!isImage">
        <el-button :size="defaultFormItemSize" type="primary" :disabled="getDisabledStatus()">点击上传</el-button>
      </template>
      <i v-else class="el-icon-plus upload-image-item"></i>
    </el-upload>
    <template v-else>
      <template v-if="isImage">
        <el-image v-for="(item, index) in uploadWidgetImpl.fileList"
          :preview-src-list="(uploadWidgetImpl.fileList || []).map(imgItem => imgItem.url)" class="table-cell-image"
          :key="index" :src="item.url" fit="fill">
        </el-image>
      </template>
      <template v-else>
        <div class="flex gap-10 flex-wrap">
          <div v-for="(item, index) in uploadWidgetImpl.fileList" :key="index" class="file flex align-center">
            <div v-if="item.url" style="flex-shrink: 0;">
              <!-- 如果是图片 -->
              <template v-if="isImg(item.url)">
                <el-image :preview-src-list="getUploadsPictuers" :src="item.url" style="width: 100px;"></el-image>
              </template>
              <!-- 如果是文件 -->
              <template v-else>
                <div class="flex align-center">
                <File-previwer :url="item.url" :name="item.name" :size="50" :option="false" showBtn></File-previwer>
              </div>
              </template>
            </div>
            <div v-else class="flex-col text-center">
              <i class="el-icon-error text-red font-20"></i>
              <span class="font-12 text-red">异常url</span>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { ImagePreview } from 'vant';

import FilePreviwer from '@/components/FilePreviewer'
import { uploadMixin } from '@/core/mixins';
import { isImage as isImg } from '@/utils/validate'
import { UploadWidget } from '@/utils/widget.js';

export default {
  name: 'onlineCustomUpload',
  props: {
    value: {
      // type: String
    },
    widget: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  mixins: [uploadMixin],
  components: {FilePreviwer},
  inject: ['form'],
  data () {
    return {
      isImage: false,
      uploadWidgetImpl: new UploadWidget(this.widget.column ? this.maxFileCount : 0),
      actionUrl: process.env.VUE_APP_UPLOAD_URL,
      ExcelImg: require('@/assets/img/Excel-Color.png'),
      PdfImg: require('@/assets/img/Pdf-Color.png'),
      WordImg: require('@/assets/img/Word-Color.png'),
      fileImage: require('@/assets/img/document.png')
    }
  },
  methods: {
    previewFile ({status, url}) {
      if (status === 'success') {
        // 如果是pdf 则直接用浏览器打开
        if (url.match(/\.(pdf)$/i)) {
          window.open(url, '_blank')
        } else if (this.isImg(url)) {
          // 获取上传列表中的图片列表
          ImagePreview({
            images: [url],
            closeable: true
          })
        } else if (url.match(/\.(doc|docx|xlsx|xls|csv|xlsm)$/i)) {
          window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url), '_blank')
        } else {
          this.$message.error('当前格式不支持预览!')
        }
      } else {
        this.$message.error('文件还未上传成功!')
      }
    },
    getDisabledStatus () {
      if (this.form().isEdit) return true;
      if (this.widget.eventInfo && this.widget.eventInfo[this.OnlineFormEventType.DISABLE]) {
        return this.widget.eventInfo[this.OnlineFormEventType.DISABLE]();
      } else {
        return this.widget.props.disabled;
      }
    },
    onValueChange () {
      // 通知表单值发生变化
      this.$emit('input', JSON.stringify(this.uploadWidgetImpl.fileList), this.widgetConfig);
    },
    handlerFileChange (file, fileList) {
      const isAllUploaded = fileList.every(item => item.status === 'success');
      if (isAllUploaded) {
        // 所有文件都已上传完成的操作
        try {
          const param = fileList.map(m => ({
            name: m.name,
            filename: m.name,
            url: m.response ? m.response.data.url : m.url
          }))
          this.uploadWidgetImpl.onFileChange(file, param);
          this.onValueChange();
        } catch (error) {
          console.log(error)
        }
      }
    },
    onRemoveFile (file, fileList) {
      this.uploadWidgetImpl.onFileChange(file, fileList);
      this.onValueChange();
    },
    onUploadError (e, file, fileList) {
      this.$message.error('文件上传失败');
    },
    onUploadLimit (files, fileList) {
      if (this.maxFileCount != null && this.maxFileCount > 0) {
        this.$message.error('已经超出最大上传个数限制');
      }
    },
    isImg (url) {
      return isImg(url)
    }
  },
  computed: {
    maxFileCount () {
      return this.widget.column ? this.widget.column.maxFileCount : 0;
    },
    buildFlowParam () {
      let flowParam = {};
      let flowData = this.form().flowData;
      if (flowData) {
        if (flowData.processDefinitionKey) flowParam.processDefinitionKey = flowData.processDefinitionKey;
        if (flowData.processInstanceId) flowParam.processInstanceId = flowData.processInstanceId;
        if (flowData.taskId) flowParam.taskId = flowData.taskId;
      }

      return flowParam;
    },
    getUploadListType () {
      if (this.maxFileCount !== 1 && this.isImage) {
        return 'picture-card';
      }
      return 'text';
    },
    getUploadFileList () {
      return this.uploadWidgetImpl ? this.uploadWidgetImpl.fileList : [];
    },
    getUploadsPictuers () {
      return this.uploadWidgetImpl.fileList.filter(m => m.url && this.isImg(m.url)).map(m => m.url)
    }
  },
  mounted () {
    this.widget.widgetImpl = this;
  },
  watch: {
    value: {
      handler (newValue) {
        if (newValue && typeof newValue === 'string') this.uploadWidgetImpl.fileList = JSON.parse(newValue)
        else this.uploadWidgetImpl.fileList = newValue
      },
      immediate: true
    },
    maxFileCount: {
      handler () {
        if (this.uploadWidgetImpl) this.uploadWidgetImpl.maxCount = this.maxFileCount;
      },
      immediate: true
    },
    'widget.column': {
      handler () {
        let column = this.widget.bindData.column || this.widget.column;
        this.isImage = column ? (column.fieldKind === this.SysOnlineFieldKind.UPLOAD_IMAGE) : false;
      },
      immediate: true
    },
    'widget.bindData.columnId': {
      handler () {
        let column = this.widget.bindData.column || this.widget.column;
        this.isImage = column ? (column.fieldKind === this.SysOnlineFieldKind.UPLOAD_IMAGE) : false;
      },
      immediate: true
    }
  }
}
</script>

<style>
.flex-wrap{
  flex-wrap: wrap;
}
.file-icon{
  width: 50px;
}
.link-style {
  color: inherit;
  text-decoration: none;
}

.gap-10 {
  gap: 10px;
}

.file {
  background-color: #FAFAFA;
  padding: 5px;
}

.font-20{
  font-size: 20px;
}

.text-red{
  color: #fc3e3e;
}
</style>
