<template>
  <el-card class="online-card-table base-card" shadow="never" :body-style="{ padding: '0px' }">
    <div slot="header" class="base-card-header">
      <span class="header-title" style="font-weight: 700; color: #606266">{{ widget.showName }}</span>
    </div>
    <el-row>
      <el-col :span="24" :style="{ height: widget.props.height != null ? widget.props.height - 53 + 'px' : undefined }">
        <OnlineCustomTable
          :dataList="tableWidget.dataList"
          :widget="widget"
          :isEdit="isEdit"
          :height="widget.props.height ? widget.props.height - 53 : 200"
          :multiSelect="batchDelete"
          :operationList="widget.operationList"
          :getTableIndex="tableWidget.getTableIndex"
          :sortChange="tableWidget.onSortChange"
          :onSelectChange="onSelectRowChange"
          :cellEdit="widget.props.cellEditer"
          @operationClick="onOperationClick"
          @onFileSelect="onIMPORT"
        />
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
/**
 * 表格组件
 */
import { mapState } from 'vuex'

import dataMutiSelect from '@/components/DataMutiSelect'
import { fetchDownloadBlob } from '@/core/http'
import { dealParams} from '@/Hooks/RelationSelect.js'
import { findItemFromList, getUUID } from '@/utils'
import { TableWidget } from '@/utils/widget.js'

import OnlineCustomTable from './OnlineCustomTable.vue'
export default {
  name: 'onlineCardTable',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    widget: {
      type: Object
    },
    isEdit: {
      type: Boolean
    }
  },
  inject: ['form'],
  components: {
    OnlineCustomTable
  },
  data () {
    return {
      selectRows: [],
      batchDelete: false,
      tableWidget: new TableWidget(this.loadTableData, this.loadTableDataVerify, false, false)
    }
  },
  methods: {
    loadTableData () {
      return Promise.resolve({
        dataList: this.value,
        totalCount: this.value.length
      })
    },
    loadTableDataVerify () {
      return true
    },
    onTableDataListChange (dataList) {
      if (!this.form().isEdit && typeof this.widget.eventInfo[this.OnlineFormEventType.AFTER_LOAD_TABLE_DATA] === 'function') {
        dataList = this.widget.eventInfo[this.OnlineFormEventType.AFTER_LOAD_TABLE_DATA](dataList)
      }
      this.$emit('input', dataList)
    },
    hasOperator (type) {
      let temp = this.getOperation(type)
      return temp && temp.enabled
    },
    getOperation (type) {
      return findItemFromList(this.widget.operationList, type, 'type')
    },
    operationVisible (type) {
      let operation = this.getOperation(type)
      return !this.form().readOnly && this.hasOperator(type) && this.form().checkOperationVisible(operation)
    },
    operationDisabled (type) {
      let operation = this.getOperation(type)
      return this.form().checkOperationDisabled(operation) || !this.form().checkOperationPermCode(operation)
    },
    onSelectRowChange (rows) {
      this.selectRows = rows
    },
    handlerEditOperate (row, res) {
      if (this.widget.relation != null) {
        if (row == null) {
          console.log('新增记录')
          // 新增记录
          row = res[this.widget.relation.variableName]
          this.onTableDataListChange([
            ...this.tableWidget.dataList,
            {
              ...row,
              __cascade_add_id__: new Date().getTime()
            }
          ])
        } else {
          console.log('更新记录')
          // 更新记录
          row = res[this.widget.relation.variableName]
          const newData = this.tableWidget.dataList.map((item) => {
            if (row.__cascade_add_id__ != null) {
              return row.__cascade_add_id__ === item.__cascade_add_id__ ? row : item
            } else {
              return row[this.widget.primaryColumnName] === item[this.widget.primaryColumnName] ? row : item
            }
          })
          this.onTableDataListChange(newData)
        }
      }
    },
    onOperationClick (operation, row, rules) {
      switch (operation.type) {
        case this.SysCustomWidgetOperationType.MUTISELECT:
          this.onMutiSelect(operation, rules)
          break
        case this.SysCustomWidgetOperationType.BATCH_DELETE:
          this.onBatchDelete(operation)
          break
        case this.SysCustomWidgetOperationType.DELETE:
          this.onDeleteRow(row)
          break
        case this.SysCustomWidgetOperationType.EXPORT:
          this.onExport(operation)
          break
        case this.SysCustomWidgetOperationType.PDF_PREVIEW:
          // 文件预览
          this.onPdfPreview(row)
          break
        case this.SysCustomWidgetOperationType.ADDLINE:
          this.onTableDataListChange([
            ...this.tableWidget.dataList,
            {
              __cascade_add_id__: new Date().getTime(),
              deleted_flag: 1
            }
          ])
          break;
        default:
          this.form().handlerOperation(operation, {
            entryId: this.flow && this.flow.entryId,
            processDefinitionKey: this.flow && this.flow.processDefinitionKey,
            taskKey: this.flow && this.flow.taskKey
          }, {
            isEdit: this.form().isEdit,
            saveData: false,
            widget: this.widget,
            rowData: row,
            callback: (res) => {
              this.handlerEditOperate(this.SysCustomWidgetOperationType.COPY === operation.type ? null : row, res)
            }
          })
      }
    },
    onExport (operation) {
      // 没有字段时 直接报错
      if (!Array.isArray(operation.exportColumnList) || operation.exportColumnList.length === 0) return this.$message.error('未添加导出字段!')
      if (!this.widget.table) return this.$message.error('临时数据不支持导出, 可以保存之后再操作导出!')
      let paramValue = ''
      try {
        const a = this.widget.datasource.variableName
        const b = this.widget.relation.masterColumn.columnName
        paramValue = this.form().formData[a][b]
      } catch (error) {
        console.log(error)
      }
      this.$confirm('是否导出表格数据？')
        .then(() => {
          let name = this.widget.relation.variableName
          let table = this.widget.table
          let params
          if (this.flow) {
            params = {
              taskId: this.flow.taskId,
              processInstanceId: this.flow.processInstanceId,
              datasourceId: table.datasource.datasourceId,
              variableName: name,
              // relationId: (table.relation || {}).relationId,
              exportInfoList: operation.exportColumnList.sort((val1, val2) => val1.showOrder - val2.showOrder)
            }
          } else {
            params = {
              datasourceId: table.datasource.datasourceId,
              variableName: name,
              // relationId: (table.relation || {}).relationId,
              exportInfoList: operation.exportColumnList.sort((val1, val2) => val1.showOrder - val2.showOrder)
            }
          }
          // 如果是子表数据 需要根据子表的过滤参数过滤
          if (paramValue !== '' && paramValue !== undefined) {
            params.filterDtoList = [{
              columnName: this.widget.relation.slaveColumn.columnName,
              filterType: 1,
              columnValue: paramValue,
              tableName: table.tableName
            }]
          }
          let httpCall
          const url = this.flow ? '/admin/flow/flowOnlineOperation/exportDynamicByDatasourceId/' : 'admin/online/onlineOperation/exportByDatasourceId/'
          const fileName = name + '_' + this.$dayjs().format('YYYY-MM-DD') + '.xlsx'
          httpCall = this.download(url + table.datasource.variableName, params, fileName)
          httpCall
            .then(() => {
              this.$message.success('导出成功！')
            })
            .catch((e) => {
              this.$message.error(e)
            })
        })
    },
    // 多选操作
    onMutiSelect (operation, rules = []) {
      let filters = []

      if (rules.length > 0) {
        const {masterTableId, tableMap, formData} = this.form()
        // 获取主表名
        const variableName = tableMap.get(masterTableId).datasource.variableName

        filters = dealParams(rules, variableName, '', formData)
      }
      // 可能没有映射  需要做非空判断
      const mappings = operation.mappings && operation.mappings.filter(m => m.mappingKey)
      this.$dialog.show('数据选择', dataMutiSelect,
        {
          area: ['80vw', '80vh'],
          offset: '100px',
          skin: 'one_to_one_query'
        },
        {
          showColumn: operation.showColumn, // 数据表 显示的字段
          dblinkId: operation.dblinkId,
          masterTableId: operation.masterTableId,
          innerDataList: this.tableWidget.dataList,
          customerPrimaryKey: operation.customerPrimaryKey, // 自定义主键
          rule: filters, // 多选表格 显示规则
          mappings: mappings // 字段映射表
        }
      ).then((res) => {
        let temp = []
        // 仅 mappings 中的数据 才会生效
        Array.isArray(res) && res.forEach(m => {
          let obj = {}
          mappings.forEach(n => {
            obj[n.mappingKey] = m[n.columnName]
          })
          temp.push(obj)
        })
        // 根据 字段映射表 处理数据
        this.handlerSelectData(temp, operation)
      }).catch(e => {
        console.info('关闭了弹窗')
      })
    },
    // 往表格中插入数据
    handlerSelectData (res, operation) {
      // 寻找主键字段
      const primaryKey = operation.customerPrimaryKey || operation.showColumn.find(m => m.primaryKey).columnName
      // 将当前列表 存储为Map
      const tempMap = new Map()
      // 2023-12-6 新增需求 多选时 不能直接覆盖已经编辑的内容
      const oldList = this.tableWidget.dataList
      res.forEach(m => {
        tempMap.set(m[primaryKey], {...m, __cascade_add_id__: getUUID()});
      });

      if (oldList.length > 0) {
        oldList.forEach(m => {
          tempMap.set(m[primaryKey], m)
        })
      }
      // 已经选择的值
      this.tableWidget.dataList = Array.from(tempMap.values());

      this.onTableDataListChange(this.tableWidget.dataList);
    },
    onIMPORT ({file}) {
      let operation = this.getOperation(this.SysCustomWidgetOperationType.IMPORT)
      const importColumnList = operation.importColumnList.map((m, i) => ({...m, showOrder: i}))
      let httpCall
      httpCall = this.upload('/admin/online/onlineOperation/importDynamicByDatasourceId', {
        file,
        importColumnList: JSON.stringify(importColumnList)
      })
      httpCall
        .then((res) => {
          this.$message.success('导入成功！')
          if (res.data) {
            let i = 1
            res.data.importList.map((item) => {
              item.__cascade_add_id__ = new Date().getTime() + i
              this.tableWidget.dataList.push(item)
              i++
            })
          }
        })
        .catch((e) => {})
    },
    onBatchDelete () {
      if (this.selectRows.length <= 0) {
        this.$message.error('请选择要批量删除的数据！')
        return
      }
      this.$confirm('是否删除选中数据？').then(() => {
        this.onTableDataListChange(
          this.tableWidget.dataList.filter((row) => {
            // 通过主键查找
            let temp = findItemFromList(this.selectRows, row[this.widget.primaryColumnName], this.widget.primaryColumnName)
            // 通过新增临时主键查找
            if (temp == null && row.__cascade_add_id__ != null) {
              temp = findItemFromList(this.selectRows, row.__cascade_add_id__, '__cascade_add_id__')
            }
            return temp == null
          })
        )
      })
    },
    onDeleteRow (data) {
      this.$confirm('是否删除当前数据？')
        .then(() => {
          this.onTableDataListChange(
            this.tableWidget.dataList.filter((row) => {
              if (data.__cascade_add_id__ != null) {
                return data.__cascade_add_id__ !== row.__cascade_add_id__
              } else {
                return data[this.widget.primaryColumnName] !== row[this.widget.primaryColumnName]
              }
            })
          )
        })
    },
    refresh () {
      this.tableWidget.refreshTable(true, 1)
    },
    refreshData (data) {
      if (data.path === 'thirdOnlineEditForm/' + this.widget.variableName) {
        this.handlerEditOperate(data.rowData, data.data)
      }
    },
    onPdfPreview (row) {
      // 由于文件由服务器生成 因此需要调用接口 下载文件 并打开
      fetchDownloadBlob('/nuxtapi/contact/preview', row, 'post', 'application/pdf').then(blobData => {
        try {
          let pdfUrl = window.URL.createObjectURL(blobData);

          window.open(pdfUrl, '_blank');
          // 如果立刻销毁 会导致 Safir 浏览器弹出窗 打开之前就无法使用 因此临时解决办法是 延迟销毁
          setTimeout(() => {
            window.URL.revokeObjectURL(pdfUrl);
          }, 1500);
        } catch (error) {
          console.error(error)
        }
      })
    }
  },
  mounted () {
    this.widget.widgetImpl = this
    window.addEventListener(
      'message',
      (event) => {
        if (event.data.type === 'refreshData') {
          this.refreshData(event.data.data)
        }
      },
      false
    )
  },
  computed: {
    ...mapState({
      flow: 'app/flow'
    })
  },
  watch: {
    value: {
      handler () {
        this.refresh()
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.base-card >>> .el-card__header {
  padding: 0px 10px !important;
}

.online-card-table >>> .vxe-table--border-line {
  border: none !important;
}
</style>
