<template>
  <div class="inline-block">
    <template v-if="url">
      <!-- Excel -->
      <div v-if="url.match(/\.(xlsx|xls)$/i)">
        <el-image class="icon handler" :style="{width: size + 'px'}"  :src="ExcelImg" @click="preview(url, name)"></el-image>
        <div class="m-r-20 m-l-10" v-if="showBtn">
          <!-- <i class="el-icon-view handler option" @click="preview(url)">&nbsp;预览</i>
          <br/> -->
          <i class="el-icon-download handler option" @click="downloadUri(url, name)">&nbsp;下载</i>
        </div>
      </div>
      <!-- PDF -->
      <div v-else-if="url.match(/\.pdf$/i)">
        <el-image class="icon handler" :style="{width: size + 'px'}"  :src="PdfImg" @click="preview(url, name)"></el-image>
        <div class="m-r-20 m-l-10"  v-if="showBtn">
          <!-- <i class="el-icon-view handler option" @click="preview(url)">&nbsp;预览</i>
          <br/> -->
          <i class="el-icon-download handler option" @click="downloadUri(url, name)">&nbsp;下载</i>
        </div>
      </div>
      <!-- Word -->
      <div v-else-if="url.match(/\.(docx|doc)$/i)">
        <el-image class="icon handler" :style="{width: size + 'px'}"  :src="WordImg" @click="preview(url, name)"></el-image>
        <div class="m-r-20 m-l-10"  v-if="showBtn">
          <!-- <i class="el-icon-view handler option" @click="preview(url)">&nbsp;预览</i>
          <br/> -->
          <i class="el-icon-download handler option" @click="downloadUri(url, name)">&nbsp;下载</i>
        </div>
      </div>
      <!-- ZIP -->
      <div v-else-if="url.match(/\.(zip|7z|rar|tar|gz|bz2)$/i)">
        <el-image class="icon" :style="{width: size + 'px'}"  :src="ZipImg" @click="noPreview()"></el-image>
        <div class="m-r-20 m-l-10"  v-if="showBtn">
          <i class="el-icon-download handler option" @click="downloadUri(url, name)">&nbsp;下载</i>
        </div>
      </div>
      <!-- other -->
      <div v-else>
        <el-image class="icon" :style="{width: size + 'px'}"  :src="require('@/assets/img/document.png')" @click="noPreview()"></el-image>
        <div class="m-r-20 m-l-10"  v-if="showBtn">
          <i class="el-icon-download handler option" @click="downloadUri(url, name)">&nbsp;下载</i>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Axios from 'axios'

import ExcelImg from '@/assets/img/Excel-Color.png'
import PdfImg from '@/assets/img/Pdf-Color.png'
import WordImg from '@/assets/img/Word-Color.png'
import ZipImg from '@/assets/img/Zip-Color.png'

export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    // 图标宽度
    size: {
      type: [String, Number],
      default: 20
    },
    // 点击后是否可以跳转 预览
    option: {
      type: Boolean,
      default: true
    },
    // 是否显示 预览和下载按钮
    showBtn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ExcelImg: ExcelImg,
      PdfImg: PdfImg,
      WordImg: WordImg,
      ZipImg: ZipImg
    }
  },
  methods: {
    noPreview () {
      if (!this.option) return
      // 不支持预览的文件
      this.$message.warning('该类型的文件不支持预览!')
    },
    preview (url) {
      if (url.match(/\.(pdf)$/i)) window.open(url, '_blank')
      else window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url), '_blank')
    },
    downloadUri (url, name) {
      Axios.get(url, { responseType: 'blob' }).then(res => {
        const blob = new Blob([res.data])
        let a = document.createElement('a')
        a.href = URL.createObjectURL(blob)
        a.download = name
        a.click()
      })
    }
  }
}

</script>

<style scoped lang='scss'>
.inline-block{
  display: inline-block;
}
.icon{
  margin: 10px 5px;
}
.option{
  color: rgb(34, 101, 235);
  font-size: 12px;

}
</style>
