<template>
    <div class="custom-label">
      <span v-if="isText">
        {{showText}}
      </span>
      <div v-if="isHtml" v-html="showText" />
      <div v-if="widget.props.showRMBText" class="money">{{ formatedMoney }}</div>
    </div>
</template>

<script>

import {numToCny} from '@/utils/money'

export default {
  name: 'onlineCustomLabel',
  props: {
    value: {
      type: [Number, String, Boolean, Array]
    },
    widget: {
      type: Object,
      required: true
    }
  },
  inject: ['form'],
  computed: {
    formatedMoney () {
      return numToCny(this.value)
    },
    isText () {
      return [
        this.SysCustomWidgetType.Label,
        this.SysCustomWidgetType.Input,
        this.SysCustomWidgetType.NumberInput,
        this.SysCustomWidgetType.RelationView,
        this.SysCustomWidgetType.TimePick,
        this.SysCustomWidgetType.NumberRange,
        this.SysCustomWidgetType.Slider,
        this.SysCustomWidgetType.Radio,
        this.SysCustomWidgetType.CheckBox,
        this.SysCustomWidgetType.Switch,
        this.SysCustomWidgetType.Select,
        this.SysCustomWidgetType.Cascader,
        this.SysCustomWidgetType.Date,
        this.SysCustomWidgetType.DateRange,
        this.SysCustomWidgetType.UserSelect,
        this.SysCustomWidgetType.DeptSelect,
        this.SysCustomWidgetType.AdvanceDataSelect,
        this.SysCustomWidgetType.DataSelect
      ].indexOf(this.widget.widgetType) !== -1;
    },
    isHtml () {
      return this.widget.widgetType === this.SysCustomWidgetType.RichEditor && this.widget.column && this.widget.column.fieldKind === this.SysOnlineFieldKind.RICH_TEXT;
    },
    showText () {
      switch (this.widget.widgetType) {
        case this.SysCustomWidgetType.Label:
        case this.SysCustomWidgetType.Input:
        case this.SysCustomWidgetType.NumberInput:
        case this.SysCustomWidgetType.Slider:
        case this.SysCustomWidgetType.RichEditor:
        case this.SysCustomWidgetType.Radio:
        case this.SysCustomWidgetType.CheckBox:
        case this.SysCustomWidgetType.Select:
        case this.SysCustomWidgetType.Cascader:
        case this.SysCustomWidgetType.UserSelect:
        case this.SysCustomWidgetType.DeptSelect:
        case this.SysCustomWidgetType.AdvanceDataSelect:
        case this.SysCustomWidgetType.DataSelect:
          return this.value == null ? (this.form().isEdit ? 'XXXXXXXXXX' : undefined) : (Array.isArray(this.value) ? this.value.join(',') : this.value);
        case this.SysCustomWidgetType.NumberRange:
        case this.SysCustomWidgetType.DateRange:
          return (Array.isArray(this.value) && this.value.length > 1) ? (this.value[0] + ' 至 ' + this.value[1]) : ((this.form().isEdit ? 'XXXXX 至 XXXXX' : undefined));
        case this.SysCustomWidgetType.Switch:
          return this.value ? '是' : '否';
        case this.SysCustomWidgetType.Date:
          if (this.widget.props.type === 'year') return this.$dayjs(this.value).format('YYYY年')
          if (this.widget.props.type === 'month') return this.$dayjs(this.value).format('YYYY年M月份')
          return this.value
        default: return '';
      }
    }
  },
  mounted () {
    this.widget.widgetImpl = this;
  }
}
</script>

<style scoped lang="scss">
.money{
  font-size: 12px;
  color: #999999;
  position: absolute;
  right: 10px;
  top: 0;
}
.custom-label{
  position: relative;
}
</style>
