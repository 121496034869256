<template>
  <div>
    <span>{{ value[0] }}</span>
    <span class="m-l-5 time-block" v-if="value[1]">{{ value[1] }}</span>
  </div>
</template>

<script>
/**
 * @prop {Date|String} time
 * @prop {Number|String} type 显示类型 0: 默认, 1: 显示到分钟  2: 显示到天
 */
export default {
  props: {
    time: {
      require: true,
      type: [Date, String, Number],
      default: ''
    },
    type: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      value: []
    };
  },
  created () {},
  methods: {
    refresh () {
      if (!this.time) return;
      let formatStr = 'YYYY-MM-DD HH:mm:ss';

      switch (this.type) {
        case 1:
        case '1':
          formatStr = 'YYYY-MM-DD HH:mm';
          break;
        case 2:
        case '2':
          formatStr = 'YYYY-MM-DD';
          break;
        default:
          break;
      }
      const timestr = this.$dayjs(this.time).format(formatStr);
      if (timestr == 'Invalid Date') {
        this.value = [this.time];
        return;
      }
      const [first, second] = timestr.split(' ');
      this.value = [first, second];
    }
  },
  watch: {
    time: {
      handler () {
        this.refresh();
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="scss">
.time-block {
  padding: 0 2px;
  border-radius: 4px;
  color: #adadad;
  font-size: 12px;
}
</style>
