import state from '../store/state.js'
// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢
const _import = require('./import-' + process.env.NODE_ENV)

function getProps (route) {
  return route.query
}

// 系统生成路由
const routers = [
  { path: '/login', component: _import('login/index'), name: 'login', props: getProps, desc: '登录' },
  { path: '/', component: _import('login/index'), name: 'root' },
  { path: '/flowDetail', component: _import('JBH/flowDetail'), props: getProps, name: 'flowDetail', meta: { title: '详情' }},
  {
    path: '/main',
    component: _import('layout/index'),
    name: 'main',
    props: getProps,
    redirect: {
      name: 'welcome'
    },
    meta: {
      title: '主页',
      showOnly: true
    },
    children: [
      { path: 'formSysUser', component: _import('upms/formSysUser/index'), name: 'formSysUser', meta: { title: '用户管理' } },
      { path: 'formSysDept', component: _import('upms/formSysDept/index'), name: 'formSysDept', meta: { title: '部门管理' } },
      { path: 'formSysRole', component: _import('upms/formSysRole/index'), name: 'formSysRole', meta: { title: '角色管理' } },
      { path: 'formSysDataPerm', component: _import('upms/formSysDataPerm/index'), name: 'formSysDataPerm', meta: { title: '数据权限管理' } },
      { path: 'formSysMenu', component: _import(state.supportColumn ? 'upms/formSysMenu/formSysColumnMenu' : 'upms/formSysMenu/index'), name: 'formSysMenu', meta: { title: '菜单列表', keepAlive: true } },
      { path: 'formSysDict', component: _import('upms/formDictManagement/index'), name: 'formSysDict', meta: { title: '字典管理', keepAlive: true } },
      { path: 'formSysOperationLog', component: _import('upms/formSysOperationLog/index'), name: 'formSysOperationLog', meta: { title: '操作日志' } },
      { path: 'formSysLoginUser', component: _import('upms/formSysLoginUser/index'), name: 'formSysLoginUser', meta: { title: '在线用户' } },
      // 岗位模块路由配置
      { path: 'formSysPost', component: _import('upms/formSysPost/index'), name: 'formSysPost', meta: { title: '岗位管理' } },
      { path: 'formSysDeptPost', component: _import('upms/formSysDeptPost/index'), name: 'formSysDeptPost', props: getProps, meta: { title: '设置部门岗位' } },
      // 在线表单模块路由配置
      { path: 'formOnlineDblink', component: _import('online/formOnlineDblink/index'), name: 'formOnlineDblink', props: getProps, meta: { title: '数据库链接' } },
      { path: 'formOnlineDict', component: _import('online/formOnlineDict/index'), name: 'formOnlineDict', props: getProps, meta: { title: '在线表单字典管理' } },
      { path: 'formOnlinePage', component: _import('online/index'), name: 'formOnlinePage', props: getProps, meta: { title: '在线表单管理' } },
      { path: 'onlineForm', component: _import('online/OnlinePageRender/index'), name: 'onlineForm', props: getProps, meta: { title: '在线表单' } },
      { path: 'formPageCategory', component: _import('online/onlinePageCategory/index'), name: 'formPageCategory', props: getProps, meta: {title: '表单分类管理', keepAlive: true}},
      // 工作流模块路由配置
      { path: 'formMessage', component: _import('workflow/formMessage/index'), name: 'formMessage', props: getProps, meta: { title: '催办消息' } },
      { path: 'formFlowCategory', component: _import('workflow/flowCategory/formFlowCategory'), name: 'formFlowCategory', props: getProps, meta: { title: '流程分类管理' } },
      { path: 'formFlowEntry', component: _import('workflow/flowEntry/formFlowEntry'), name: 'formFlowEntry', props: getProps, meta: { title: '流程设计' } },
      { path: 'formAllInstance', component: _import('workflow/taskManager/formAllInstance'), name: 'formAllInstance', props: getProps, meta: { title: '流程实例' } },
      { path: 'formMyTask', component: _import('workflow/taskManager/formMyTask'), name: 'formMyTask', props: getProps, meta: { title: '我的待办' } },
      { path: 'formMyApprovedTask', component: _import('workflow/taskManager/formMyApprovedTask'), name: 'formMyApprovedTask', props: getProps, meta: { title: '已办任务' } },
      { path: 'formMyHistoryTask', component: _import('workflow/taskManager/formMyHistoryTask'), name: 'formMyHistoryTask', props: getProps, meta: { title: '历史流程' } },
      { path: 'formMyInitiativeTask', component: _import('workflow/taskManager/formMyInitiativeTask'), name: 'formMyInitiativeTask', props: getProps, meta: {title: '我的发起'}},
      { path: 'formFlowEntryDeleted', component: _import('workflow/flowEntry/formFlowEntryDeleted'), name: 'formFlowEntryDeleted', props: getProps, meta: { title: '流程设计(已删除)' } },
      {
        path: 'handlerFlowTask',
        component: _import('workflow/handlerFlowTask/index'),
        name: 'handlerFlowTask',
        props: getProps,
        meta: { title: '流程处理' },
        children: [
          // 静态表单路由设置
          {
            path: 'firstPage',
            component: _import('workflow/pages/cpsp/firstPage'),
            name: 'firstPage',
            props: getProps,
            meta: {title: '产品审批首页'}
          },
          {
            path: 'selectedProduct',
            component: _import('workflow/pages/cpsp/selectedProduct'),
            name: 'selectedProduct',
            props: getProps,
            meta: {title: '已选择产品页'}
          }
        ]
      },
      // 报表路由
      {
        path: 'reportRender',
        component: _import('report/formReportPage/reportRender/index'),
        name: 'reportRender',
        props: getProps,
        meta: { title: '报表' }
      },
      {
        path: 'formReportPage',
        component: _import('report/formReportPage/index'),
        name: 'formReportPage',
        meta: { title: '报表页面' }
      },
      {
        path: 'formPrintManage',
        component: _import('report/formPrint/index'),
        name: 'formPrintManage',
        meta: {title: '打印管理'}
      },
      // 移动端管理
      {
        path: 'formSudoku',
        component: _import('mobile/formMobileEntry/formSudoku'),
        name: 'formSudoku',
        props: {
          entryType: 1
        },
        meta: {title: '九宫格配置'}
      },
      {
        path: 'formBanner',
        component: _import('mobile/formMobileEntry/formBanner'),
        name: 'formBanner',
        props: {
          entryType: 0
        },
        meta: {title: '轮播图配置配置'}
      },
      { path: 'welcome', component: _import('welcome/index'), name: 'welcome', meta: {title: '欢迎', keepAlive: true} },
      { path: 'flowInstanceDelete', component: _import('JBH/deleteInstance'), name: 'flowInstanceDelete', meta: {title: '工单删除'} },
      { path: 'test', component: _import('test/index'), name: 'test', meta: { ignoreAuth: true } },
      { path: 'versionManager', component: _import('VersionManager/index'), name: 'versionManager', meta: {title: '版本管理'} },
      { path: 'dataSyncTaskManager', component: _import('dataSyncTaskManager/index'), name: 'dataSyncTaskManager', meta: {title: '数据同步任务管理'} },
      { path: 'esignContract', component: _import('ESignManage/contract'), name: 'esignContract', meta: {title: '合同模板管理'} },
      { path: 'SealManage', component: _import('ESignManage/seal'), name: 'SealManage', meta: {title: '印章管理'} },
      { path: 'ReSentMsg', component: _import('ESignManage/ReSentMsg'), name: 'ReSentMsg', meta: {title: '回调通知重发'}},
      { path: 'esignFlowList', component: _import('ESignManage/esignFlowList'), name: 'esignFlowList', meta: {title: 'E签宝流程'}},
      { path: 'GoodsNameMapping', component: _import('JBH/GoodsNameMapping'), name: 'GoodsNameMapping', meta: { title: '商品映射管理'}},
      { path: 'ExcelImport', component: _import('JBH/Excel-import'), name: 'ExcelImport', meta: { title: 'Excel订单导入'}},
      { path: 'ExcelTemplate', component: _import('JBH/ExcelTemplate'), name: 'ExcelTemplate', meta: { title: 'Excel模版管理'}},
      { path: 'generateCommits', component: _import('JBH/generateCommits'), name: 'generateCommits', meta: { title: '评论生成'}}

    ]
  },
  // 第三方接入路由
  {
    path: '/thirdParty',
    name: 'thirdParty',
    props: getProps,
    component: _import('thirdParty/index'),
    children: [
      // 流程分类列表
      {
        path: 'thirdFormFlowCategory',
        name: 'thirdFormFlowCategory',
        props: getProps,
        component: _import('workflow/flowCategory/formFlowCategory')
      },
      // 流程分类 新增、编辑
      {
        path: 'thirdAddFormFlowCategory',
        name: 'thirdAddFormFlowCategory',
        props: getProps,
        component: _import('workflow/flowCategory/formEditFlowCategory')
      },
      // 流程实例列表
      {
        path: 'thirdFormAllInstance',
        name: 'thirdFormAllInstance',
        props: getProps,
        component: _import('workflow/taskManager/formAllInstance')
      },
      // 流程图
      {
        path: 'thirdFormTaskProcessViewer',
        name: 'thirdFormTaskProcessViewer',
        props: getProps,
        component: _import('workflow/taskManager/formTaskProcessViewer')
      },
      // 流程干涉
      {
        path: 'thirdFormInterposeInstance',
        name: 'thirdFormInterposeInstance',
        props: getProps,
        component: _import('workflow/taskManager/formInterposeInstance')
      },
      // 数据补偿
      {
        path: 'thirdFormCompensationInstance',
        name: 'thirdFormCompensationInstance',
        props: getProps,
        component: _import('workflow/taskManager/processCompensation')
      },
      // 流程终止
      {
        path: 'thirdFormStopTaskInstance',
        name: 'thirdFormStopTaskInstance',
        props: getProps,
        component: _import('workflow/taskManager/stopTask')
      },
      // 选择用户-处理用户
      {
        path: 'thirdTaskUserSelect',
        name: 'thirdTaskUserSelect',
        props: getProps,
        component: _import('workflow/components/TaskUserSelect')
      },
      // 选择用户-跳转节点
      {
        path: 'thirdSelectUserTask',
        name: 'thirdSelectUserTask',
        props: getProps,
        component: _import('workflow/components/UserTaskSelect/userTaskSelectDlg')
      },
      // 流程设计
      {
        path: 'thirdFormFlowEntry',
        name: 'thirdFormFlowEntry',
        props: getProps,
        component: _import('workflow/flowEntry/formFlowEntry')
      },
      {
        path: 'thirdFormEditFlowEntry',
        name: 'thirdFormEditFlowEntry',
        props: getProps,
        component: _import('workflow/flowEntry/formEditFlowEntry')
      },
      {
        path: 'thirdFormPublishedFlowEntry',
        name: 'thirdFormPublishedFlowEntry',
        props: getProps,
        component: _import('workflow/flowEntry/formPublishedFlowEntry')
      },
      {
        path: 'thirdHandlerFlowTask',
        name: 'thirdHandlerFlowTask',
        props: getProps,
        component: _import('workflow/handlerFlowTask/index')
      },
      // 流程设计-候选用户组
      {
        path: 'thirdTaskGroupSelect',
        name: 'thirdTaskGroupSelect',
        props: getProps,
        component: _import('workflow/components/TaskGroupSelect')
      },
      // 流程设计-选择岗位
      {
        path: 'thirdTaskPostSelect',
        name: 'thirdTaskPostSelect',
        props: getProps,
        component: _import('workflow/components/TaskPostSelect')
      },
      // 流程设计-抄送
      {
        path: 'thirdAddCopyForItem',
        name: 'thirdAddCopyForItem',
        props: getProps,
        component: _import('workflow/components/CopyForSelect/addCopyForItem')
      },
      // 流程设计-抄送
      {
        path: 'thirdEditOperation',
        name: 'thirdEditOperation',
        props: getProps,
        component: _import('workflow/package/refactor/form/formEditOperation')
      },
      // 流程设计-添加变量
      {
        path: 'thirdFormEditFlowEntryVariable',
        name: 'thirdFormEditFlowEntryVariable',
        props: getProps,
        component: _import('workflow/flowEntry/formEditFlowEntryVariable')
      },
      // 流程设计-新建状态
      {
        path: 'thirdFormEditFlowEntryStatus',
        name: 'thirdFormEditFlowEntryStatus',
        props: getProps,
        component: _import('workflow/flowEntry/formEditFlowEntryStatus')
      },
      // 流程设计-新建状态
      {
        path: 'thirdTaskCommit',
        name: 'thirdTaskCommit',
        props: getProps,
        component: _import('workflow/components/TaskCommit')
      },
      // 待办任务
      {
        path: 'thirdFormMyTask',
        name: 'thirdFormMyTask',
        props: getProps,
        component: _import('workflow/taskManager/formMyTask')
      },
      // 历史任务
      {
        path: 'thirdFormMyHistoryTask',
        name: 'thirdFormMyHistoryTask',
        props: getProps,
        component: _import('workflow/taskManager/formMyHistoryTask')
      },
      // 已办任务
      {
        path: 'thirdFormMyApprovedTask',
        name: 'thirdFormMyApprovedTask',
        props: getProps,
        component: _import('workflow/taskManager/formMyApprovedTask')
      },
      // 在线表单部分
      {
        path: 'thirdOnlineForm',
        name: 'thirdOnlineForm',
        props: getProps,
        component: _import('online/OnlinePageRender/index')
      },
      {
        path: 'thirdOnlineEditForm',
        name: 'thirdOnlineEditForm',
        props: getProps,
        component: _import('online/OnlinePageRender/OnlineEditForm/index')
      },
      {
        path: 'thirdFormOnlineDict',
        name: 'thirdFormOnlineDict',
        props: getProps,
        component: _import('online/formOnlineDict/index')
      },
      {
        path: 'thirdEditOnlineDict',
        name: 'thirdEditOnlineDict',
        props: getProps,
        component: _import('online/formOnlineDict/editOnlineDict')
      },
      {
        path: 'thirdOnlinePage',
        name: 'thirdOnlinePage',
        props: getProps,
        component: _import('online/index')
      },
      {
        path: 'thirdEditOnlinePage',
        name: 'thirdEditOnlinePage',
        props: getProps,
        component: _import('online/editOnlinePage/index')
      },
      {
        path: 'thirdEditOnlineForm',
        name: 'thirdEditOnlineForm',
        props: getProps,
        component: _import('online/editOnlinePage/editOnlineForm')
      },
      {
        path: 'thirdEditPageDatasource',
        name: 'thirdEditPageDatasource',
        props: getProps,
        component: _import('online/editOnlinePage/editOnlinePageDatasource')
      },
      {
        path: 'thirdEditPageRelation',
        name: 'thirdEditPageRelation',
        props: getProps,
        component: _import('online/editOnlinePage/editOnlinePageDatasourceRelation')
      },
      {
        path: 'thirdSetOnlineTableColumnRule',
        name: 'thirdSetOnlineTableColumnRule',
        props: getProps,
        component: _import('online/editOnlinePage/setOnlineTableColumnRule')
      },
      {
        path: 'thirdEditVirtualColumnFilter',
        name: 'thirdEditVirtualColumnFilter',
        props: getProps,
        component: _import('online/editOnlinePage/editVirtualColumnFilter')
      },
      {
        path: 'thirdEditTableColumn',
        name: 'thirdEditTableColumn',
        props: getProps,
        component: _import('online/formDesign/editTableColumn')
      },
      {
        path: 'thirdEditCustomFormOperate',
        name: 'thirdEditCustomFormOperate',
        props: getProps,
        component: _import('online/formDesign/components/EditCustomFormOperate')
      },
      {
        path: 'thirdEditFormField',
        name: 'thirdEditFormField',
        props: getProps,
        component: _import('online/formDesign/components/EditFormField')
      },
      {
        path: 'thirdEditDictParamValue',
        name: 'thirdEditDictParamValue',
        props: getProps,
        component: _import('online/formDesign/components/CustomWidgetDictSetting/editDictParamValue')
      },
      {
        path: 'thirdEditOnlineTableColumn',
        name: 'thirdEditOnlineTableColumn',
        props: getProps,
        component: _import('online/formDesign/components/OnlineTableColumnSetting/editOnlineTableColumn')
      },
      {
        path: 'thirdEditOnlineTabPanel',
        name: 'thirdEditOnlineTabPanel',
        props: getProps,
        component: _import('online/formDesign/components/OnlineTabPanelSetting/editOnlineTabPanel')
      },
      {
        path: 'thirdOnlineDblink',
        name: 'thirdOnlineDblink',
        props: getProps,
        component: _import('online/formOnlineDblink/index')
      },
      {
        path: 'thirdEditOnlineDblink',
        name: 'thirdEditOnlineDblink',
        props: getProps,
        component: _import('online/formOnlineDblink/editOnlineDblink')
      },
      // 打印部分
      {
        path: 'thirdPrint',
        name: 'thirdPrint',
        props: getProps,
        component: _import('report/formPrint/index')
      },
      {
        path: 'thirdEditPrintGroup',
        name: 'thirdEditPrintGroup',
        props: getProps,
        component: _import('report/formPrint/editPrintManageGroup')
      },
      {
        path: 'thirdPrintPreview',
        name: 'thirdPrintPreview',
        props: getProps,
        component: _import('report/formPrint/components/previewPrint')
      },
      {
        path: 'thirdEditPrint',
        name: 'thirdEditPrint',
        props: getProps,
        component: _import('report/formPrint/editPrintTemplate')
      },
      // 设置数据集参数
      {
        path: 'thirdSetReportDatasetParam',
        name: 'thirdSetReportDatasetParam',
        props: getProps,
        component: _import('report/components/SetReportDatasetParam')
      },
      // 设置字段过滤
      {
        path: 'thirdEditReportColumnFilter',
        name: 'thirdEditReportColumnFilter',
        props: getProps,
        component: _import('report/components/ReportColumnFilter/editReportColumnFilter')
      },
      // 设置字段排序
      {
        path: 'thirdEditReportColumnOrder',
        name: 'thirdEditReportColumnOrder',
        props: getProps,
        component: _import('report/components/ReportColumnOrder/editReportColumnOrder')
      },
      // 数据集
      {
        path: 'thirdReportDataset',
        name: 'thirdReportDataset',
        props: getProps,
        component: _import('report/formReportDataset/index')
      },
      {
        path: 'thirdEditReportDataset',
        name: 'thirdEditReportDataset',
        props: getProps,
        component: _import('report/formReportDataset/editReportDataset')
      },
      {
        path: 'thirdEditReportDatasetGroup',
        name: 'thirdEditReportDatasetGroup',
        props: getProps,
        component: _import('report/formReportDataset/editReportDatasetGroup')
      },
      {
        path: 'thirdEditDatasetParam',
        name: 'thirdEditDatasetParam',
        props: getProps,
        component: _import('report/formReportDataset/components/editDatasetParam')
      },
      {
        path: 'thirdEditDatasetRelation',
        name: 'thirdEditDatasetRelation',
        props: getProps,
        component: _import('report/formReportDataset/components/editReportRelation')
      },
      // 数据库链接
      {
        path: 'thirdReportDblink',
        name: 'thirdReportDblink',
        props: getProps,
        component: _import('report/formReportDblink/index')
      },
      {
        path: 'thirdEditReportDblink',
        name: 'thirdEditReportDblink',
        props: getProps,
        component: _import('report/formReportDblink/editReportDblink')
      },
      // 报表字典
      {
        path: 'thirdReportDict',
        name: 'thirdReportDict',
        props: getProps,
        component: _import('report/formReportDict/index')
      },
      {
        path: 'thirdEditReportDict',
        name: 'thirdEditReportDict',
        props: getProps,
        component: _import('report/formReportDict/editReportDict')
      },
      // 报表
      {
        path: 'thirdReportPage',
        name: 'thirdReportPage',
        props: getProps,
        component: _import('report/formReportPage/index')
      },
      {
        path: 'thirdEditReportPageGroup',
        name: 'thirdEditReportPageGroup',
        props: getProps,
        component: _import('report/formReportPage/editReportPageGroup')
      },
      {
        path: 'thirdEditReportPage',
        name: 'thirdEditReportPage',
        props: getProps,
        component: _import('report/formReportPage/editReportPage/index')
      },
      {
        path: 'thirdEditReportFormParam',
        name: 'thirdEditReportFormParam',
        props: getProps,
        component: _import('report/formReportPage/reportDesign/components/CustomFormSetting/editFormParam')
      },
      {
        path: 'thirdEditReportFormOperate',
        name: 'thirdEditReportFormOperate',
        props: getProps,
        component: _import('report/formReportPage/reportDesign/components/CustomReportOperationSetting/editReportOperation')
      },
      {
        path: 'thirdEditReportRouterOperate',
        name: 'thirdEditReportRouterOperate',
        props: getProps,
        component: _import('report/formReportPage/reportDesign/components/CustomReportOperationSetting/editRouteOperation')
      },
      {
        path: 'thirdReport',
        name: 'thirdReport',
        props: getProps,
        component: _import('report/formReportPage/reportRender/index')
      },
      {
        path: 'thirdReportForm',
        name: 'thirdReportForm',
        props: getProps,
        component: _import('report/formReportPage/reportRender/ReportForm/index')
      },
      // 通用
      {
        path: 'thirdEditDictParamValue2',
        name: 'thirdEditDictParamValue2',
        props: getProps,
        component: _import('components/WidgetAttributeSetting/components/CustomWidgetDictSetting/editDictParamValue', 'online')
      },
      {
        path: 'thirdEditOnlineTableColumn2',
        name: 'thirdEditOnlineTableColumn2',
        props: getProps,
        component: _import('components/WidgetAttributeSetting/components/OnlineTableColumnSetting/editOnlineTableColumn', 'online')
      },
      {
        path: 'thirdEditOnlineTabPanel2',
        name: 'thirdEditOnlineTabPanel2',
        props: getProps,
        component: _import('components/WidgetAttributeSetting/components/OnlineTabPanelSetting/editOnlineTabPanel', 'online')
      },
      {
        path: 'thirdSelectDept',
        name: 'thirdSelectDept',
        props: getProps,
        component: _import('DeptSelect/deptSelectDlg', 'components')
      },
      {
        path: 'thirdSelectUser',
        name: 'thirdSelectUser',
        props: getProps,
        component: _import('UserSelect/userSelectDlg', 'components')
      },
      {
        path: 'thirdSelectData',
        name: 'thirdSelectData',
        props: getProps,
        component: _import('components/OnlineCustomDataSelect/dataSelect', 'online')
      },
      {
        path: 'thirdEditDataViewColumn',
        name: 'thirdEditDataViewColumn',
        props: getProps,
        component: _import('components/WidgetAttributeSetting/components/ChartDatasetSetting/editDataViewTableColumn', 'online')
      },
      {
        path: 'thirdEditWidgetColumnBind',
        name: 'thirdEditWidgetColumnBind',
        props: getProps,
        component: _import('components/WidgetAttributeSetting/components/ChartDatasetSetting/editColumnBind', 'online')
      },
      {
        path: 'thirdSetDatasetPaam',
        name: 'thirdSetDatasetPaam',
        props: getProps,
        component: _import('components/SetReportDatasetParam/index', 'online')
      },
      {
        path: 'thirdEditWidgetCategroyColumn',
        name: 'thirdEditWidgetCategroyColumn',
        props: getProps,
        component: _import('components/WidgetAttributeSetting/components/ChartDatasetSetting/editWidgetCategroyColumn', 'online')
      },
      {
        path: 'thirdEditWidgetIndexColumn',
        name: 'thirdEditWidgetIndexColumn',
        props: getProps,
        component: _import('components/WidgetAttributeSetting/components/ChartDatasetSetting/editWidgetIndexColumn', 'online')
      },
      {
        path: 'thirdEditWidgetOrderInfo',
        name: 'thirdEditWidgetOrderInfo',
        props: getProps,
        component: _import('components/WidgetAttributeSetting/components/ChartDatasetSetting/editWidgetOrderInfo', 'online')
      },
      {
        path: 'thirdEditReportColumnFilter2',
        name: 'thirdEditReportColumnFilter2',
        props: getProps,
        component: _import('components/WidgetAttributeSetting/components/ChartDatasetSetting/editReportColumnFilter', 'online')
      },
      {
        path: 'thirdEditCustomEvent',
        name: 'thirdEditCustomEvent',
        props: getProps,
        component: _import('components/CustomEventSetting/editCustomEvent', 'online')
      }
    ]
  }
]

export default routers
