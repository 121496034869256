/**
 * 解析excel文件
 * @param {Object} sender this
 * @param {*} file excel 文件
 * @param {Object} param 需要包含 atasourceId 字段
 * @param {Array} columnList 列名字段
 */
export function dealExcelData (sender, {file}, { datasourceId }, columnList) {
  columnList.forEach((m, i) => { m.showOrder = i })
  return new Promise((resolve, reject) => {
    sender.upload('/admin/online/onlineOperation/importBusinessExcelByDatasourceId', {
      importColumnList: JSON.stringify(columnList),
      datasourceId,
      file
    }).then(res => {
      resolve(res)
    }).catch(e => {
      sender.$message.error('导入失败!')
    })
  })
}
