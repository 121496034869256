<template>
  <div class="m-b-10">
    <el-button-group v-if="showConfig">
      <template v-for="(item, index) in useCustomerConfig">
        <el-button size="small" :type="dealedType(index)" :style="dealedStyle(index)" :key="index" @click="setCustomTime(index)">{{ item.label }}</el-button>
      </template>
    </el-button-group>
    <el-button-group v-else>
      <el-button :type="dealedType(0)" :style="dealedStyle(0)" size="small" @click="setTime(0)">今日</el-button>
      <el-button :type="dealedType(1)" :style="dealedStyle(1)" size="small" @click="setTime(1)">本周</el-button>
      <el-button :type="dealedType(2)" :style="dealedStyle(2)" size="small" @click="setTime(2)">当月</el-button>
      <el-button :type="dealedType(3)" :style="dealedStyle(3)" size="small" @click="setTime(3)">本年</el-button>
    </el-button-group>
  </div>

</template>

<script>
import dayjs from 'dayjs'
export default {
  props: {
    useCustomerConfig: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      current: -1, // 当前选中的时间范围
      showConfig: false
    }
  },
  created () {
  },
  methods: {
    setTime (index) {
      this.current = index
      const [start, end] = this.defaultValue[index]
      this.$emit('input', [dayjs(start).format('YYYY-MM-DD HH:mm:ss'), dayjs(end).format('YYYY-MM-DD HH:mm:ss')])
    },
    setCustomTime (index) {
      this.current = index
      let { start, end } = this.useCustomerConfig[index]
      /* eslint no-eval: 0 */
      start = typeof eval(start) === 'function' ? eval(start)() : eval(start)
      /* eslint no-eval: 0 */
      end = typeof eval(end) === 'function' ? eval(end)() : eval(end)

      this.$emit('input', [dayjs(start).format('YYYY-MM-DD HH:mm:ss'), dayjs(end).format('YYYY-MM-DD HH:mm:ss')])
    }
  },
  computed: {
    defaultValue () {
      // 计算本周的开始
      const weekStart = new Date()
      weekStart.setDate(weekStart.getDate() - weekStart.getDay())
      weekStart.setHours(0, 0, 0, 0)
      // 计算本周的结束
      const weekEnd = new Date()
      weekEnd.setDate(weekEnd.getDate() + (6 - weekEnd.getDay()))
      weekEnd.setHours(23, 59, 59, 999)

      // 计算本月的开始和结束时间
      const monthStart = new Date()
      monthStart.setDate(1)
      monthStart.setHours(0, 0, 0, 0)
      const monthEnd = new Date()
      monthEnd.setMonth(monthEnd.getMonth() + 1)
      monthEnd.setDate(0)
      monthEnd.setHours(23, 59, 59, 999)

      // 计算本年的开始和结束时间
      const yearStart = new Date()
      yearStart.setMonth(0)
      yearStart.setDate(1)
      yearStart.setHours(0, 0, 0, 0)
      const yearEnd = new Date()
      yearEnd.setFullYear(yearEnd.getFullYear() + 1)
      yearEnd.setMonth(0)
      yearEnd.setDate(1)
      yearEnd.setHours(0, 0, 0, 0)
      return [
        [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 999)],
        [weekStart, weekEnd],
        [monthStart, monthEnd],
        [yearStart, yearEnd]
      ]
    },
    dealedType () {
      return function (index) {
        if (this.$attrs.backgroundColor || this.$attrs.activeColor) return ''
        return this.current === index ? 'primary' : 'default'
      }
    },
    dealedStyle () {
      return function (index) {
        if (this.$attrs.backgroundColor || this.$attrs.activeColor) {
          if (this.current === index) {
            return {
              backgroundColor: this.$attrs.activeColor || '#409EFF',
              color: '#fff'
            }
          }
          return {
            backgroundColor: this.$attrs.backgroundColor || '#8C8C8C',
            color: '#fff'
          }
        }
        return {}
      }
    }
  },
  watch: {
    useCustomerConfig: {
      handler () {
        this.showConfig = this.useCustomerConfig.length > 0
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
